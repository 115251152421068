import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {memo, useCallback, useMemo} from 'react';
import Select, {
  Props as SelectProps,
  ClassNamesConfig,
  components,
  InputProps,
  MultiValueRemoveProps,
  OptionProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';

export type Option = {
  label: string;
  value: string;
  iconPrimary?: string;
  iconSecondary?: string;
};

type Props = {
  value: string | null;
  options: Option[];
  title: string;
  placeholder?: string;
  onChange: (value: string) => void;
} & Omit<SelectProps<Option>, 'value' | 'options' | 'onChange' | 'classNames'>;

const MetaPresetDropdown = memo(
  ({value, options, title, placeholder, onChange, ...props}: Props) => {
    const {_} = useLingui();
    const selectedOption = useMemo(
      () => options.find(option => option.value === value) || null,
      [options, value],
    );

    const handleChange = useCallback(
      (selectedOption: any) => {
        if (value !== selectedOption?.value) {
          onChange(selectedOption ? selectedOption.value : null);
        }
      },
      [value, onChange],
    );

    const classNames: ClassNamesConfig<Option> = useMemo(
      () => ({
        indicatorSeparator: () => 'hidden',
        clearIndicator: () => '!text-foreground',
        dropdownIndicator: () => '!text-foreground',
        option: ({isSelected, isFocused}) =>
          `!text-foreground !flex !flex-row items-center gap-2 ${isSelected || isFocused ? ' !bg-primary' : ''}`,
        input: () => '!text-foreground',
        singleValue: () =>
          '!text-foreground !flex !flex-row items-center gap-2',
        multiValueLabel: () =>
          '!text-foreground !flex !flex-row items-center gap-2',
        multiValue: () => '!text-foreground !rounded-lg !bg-primary',
        menu: () => '!bg-[#3c3c3c] !rounded-xl overflow-hidden',
        control: () =>
          '!bg-foreground/10 !border-none !shadow-none !rounded-xl !py-[2px]',
        container: () => '!text-sm',
      }),
      [],
    );

    const formatOptionLabel = useCallback((option: Option) => {
      const icons = [option.iconPrimary, option.iconSecondary].filter(Boolean);

      return (
        <>
          {icons.length === 1 && (
            <img
              src={option.iconPrimary}
              alt={option.label}
              className="h-6 w-6 rounded-full"
            />
          )}
          {icons.length === 2 && (
            <div className="relative flex h-7 w-7 shrink-0">
              <img
                src={option.iconSecondary}
                alt={option.label}
                className="absolute right-0 top-0 z-[0] h-5 w-5 rounded-full"
              />
              <img
                src={option.iconPrimary}
                alt={option.label}
                className="absolute bottom-0 left-0 z-[0] h-5 w-5 rounded-full"
              />
            </div>
          )}
          {option.label}
        </>
      );
    }, []);

    return (
      <div className="flex w-full flex-col gap-2">
        <div className="text-sm text-foreground/50">{title}</div>
        <Select<Option>
          {...props}
          aria-label={_(msg`Meta preset ${title}`)}
          placeholder={placeholder ?? 'Select an option'}
          options={options}
          isMulti={false}
          value={selectedOption}
          onChange={handleChange}
          classNames={classNames}
          formatOptionLabel={formatOptionLabel}
          components={{
            Option: (props: OptionProps<Option>) => {
              const innerProps = {
                ...props.innerProps,
                'aria-disabled': props.isDisabled,
                'aria-label': _(msg`Meta preset ${props.label} option`),
                'data-amp-track-label': `Meta preset ${title} option`,
              };

              return <components.Option {...props} innerProps={innerProps} />;
            },
            Input: (props: InputProps<Option>) => (
              <components.Input
                {...props}
                data-amp-track-label={`Meta preset ${title}`}
              />
            ),
            MultiValueRemove: (props: MultiValueRemoveProps<Option>) => {
              const innerProps = {
                ...props.innerProps,
                'aria-label': _(msg`Meta preset ${title} remove`),
                'data-amp-track-label': `Meta preset ${title} remove`,
              };

              return (
                <components.MultiValueRemove
                  {...props}
                  innerProps={innerProps}
                />
              );
            },
            DropdownIndicator: (props: DropdownIndicatorProps<Option>) => {
              const innerProps = {
                ...props.innerProps,
                'aria-label': _(msg`Meta preset ${title} toggle`),
                'data-amp-track-label': `Meta preset ${title} toggle`,
              };

              return (
                <components.DropdownIndicator
                  {...props}
                  aria-label={_(msg`Meta preset ${title} toggle wrapper`)}
                  data-amp-track-label={`Meta preset ${title} toggle wrapper`}
                  innerProps={innerProps}
                />
              );
            },
            ClearIndicator: (props: ClearIndicatorProps<Option>) => {
              const innerProps = {
                ...props.innerProps,
                'aria-label': _(msg`Meta preset ${title} clear`),
                'data-amp-track-label': `Meta preset ${title} clear`,
              };

              return (
                <components.ClearIndicator
                  {...props}
                  aria-label={_(msg`Meta preset ${title} clear wrapper`)}
                  data-amp-track-label={`Meta preset ${title} clear wrapper`}
                  innerProps={innerProps}
                />
              );
            },
          }}
        />
      </div>
    );
  },
);

MetaPresetDropdown.displayName = 'MetaPresetDropdown';

export default MetaPresetDropdown;
