import {memo, useMemo} from 'react';
import {ConnectMetric as ConnectMetricType} from '../types';
import {twMerge} from 'tailwind-merge';
import {Button} from '@nextui-org/react';
import {getIntegrationIcon} from '@/common/integrations';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import {useNavigate} from 'react-router-dom';

export const ConnectMetric = memo(
  ({metric, className}: {metric: ConnectMetricType; className?: string}) => {
    const navigate = useNavigate();
    const {_} = useLingui();
    const text = useMemo(() => {
      switch (metric.value) {
        case 'shopify':
          return _(msg`Connect Shopify to Unlock ->`);
        default:
          return metric.value;
      }
    }, [metric.value, _]);

    return (
      <div
        className={twMerge(
          'flex flex-col justify-between gap-3 text-sm',
          className,
        )}>
        <h1 className="text-sm text-foreground/50">{metric.title}</h1>
        <Button
          aria-label={_(msg`Connect ${metric.value}`)}
          data-amp-track-label={`Connect ${metric.value}`}
          disableRipple
          radius="sm"
          variant="light"
          className="items-center px-0 data-[hover=true]:bg-transparent"
          onPress={() => navigate('connect-shopify')}>
          <div className="h-5 w-5 shrink-0">
            <img
              className="object-contain"
              src={getIntegrationIcon(metric.value, 'small')}
              alt={metric.value}
            />
          </div>
          <span className="whitespace-break-spaces text-start text-xs">
            {text}
          </span>
        </Button>
      </div>
    );
  },
);

ConnectMetric.displayName = 'ConnectMetric';
