import {API_LIMIT} from '@/config';
import {useQuery} from '@apollo/client';
import {Trans} from '@lingui/macro';
import {memo, useEffect, useMemo} from 'react';
import {useInView} from 'react-intersection-observer';
import {Creative} from './creaties/Creative';
import PortalSpinner from '@/components/PortalSpinner';
import {GET_FLOW_RUN_ASSET_CONNECTION} from '@/common/queries';

export const AdsSection = memo(() => {
  const {ref: lastItemRef, inView: isLastItemInView} = useInView();
  const {data, fetchMore} = useQuery(GET_FLOW_RUN_ASSET_CONNECTION, {
    variables: {
      filter: {platformId: {$ne: null}},
      first: API_LIMIT,
      order: {createdAt: 'desc'},
    },
  });
  const pageInfo = data?.connection.pageInfo;
  const creatives = useMemo(() => {
    return data?.connection.edges.map(e => e.node) ?? [];
  }, [data]);

  useEffect(() => {
    if (isLastItemInView && pageInfo?.hasNextPage) {
      fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [isLastItemInView, pageInfo?.hasNextPage, pageInfo?.endCursor, fetchMore]);

  return (
    <div className="flex flex-col gap-5 pb-4">
      <h1 className="text-xl font-semibold">
        <Trans>Ads</Trans>
      </h1>
      <ul className="flex flex-col gap-2">
        {creatives.map(creative => (
          <li key={creative.id}>
            <Creative {...creative} />
          </li>
        ))}
        {pageInfo?.hasNextPage && (
          <PortalSpinner
            ref={lastItemRef}
            size="md"
            className="w-full"
            classNames={{wrapper: 'm-2'}}
          />
        )}
      </ul>
    </div>
  );
});

AdsSection.displayName = 'AdsSection';
