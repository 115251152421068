import {useFlowContext} from '@/hooks';
import {FlowSetting as FlowSettingType} from '@/common/types';
import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Button, ModalBody} from '@nextui-org/react';
import {msg, Trans} from '@lingui/macro';
import {ModalHeader} from '@/components/Modal';
import FlowSetting from '@/components/FlowSetting';
import Stars02Icon from '@/components/icons/Stars02Icon';
import {useRecommendedSettingsHandler} from '@/components/FlowSetting/hooks';
import {useLingui} from '@lingui/react';
import TrackButton from '@/components/buttons/TrackButton';

const FineTuneCampaignSettings = memo(() => {
  const {_} = useLingui();
  const navigate = useNavigate();
  const {settings: allSettings, updateSettingValue} = useFlowContext();
  const settings = useMemo(() => {
    return allSettings.filter(setting => !setting.featured);
  }, [allSettings]);
  const [localSettings, setLocalSettings] = useState(settings);

  const stateSettingsMap = useMemo(() => {
    return settings.reduce(
      (acc, setting) => {
        acc[setting.id] = setting;
        return acc;
      },
      {} as Record<string, FlowSettingType>,
    );
  }, [settings]);

  const {hasChanged, getRevertedSettings} =
    useRecommendedSettingsHandler(settings);

  const localHasChanged = useMemo(() => {
    return localSettings.some(
      setting => {
        const storedSetting = stateSettingsMap[setting.id];
        return storedSetting.value !== setting.value;
      },
      [localSettings, stateSettingsMap],
    );
  }, [localSettings, stateSettingsMap]);

  const revertSettings = useCallback(() => {
    const revertedSettings = getRevertedSettings();

    for (const setting of revertedSettings) {
      updateSettingValue(setting.id, setting.value);
    }
  }, [getRevertedSettings, updateSettingValue]);

  const updateLocalSetting = useCallback(
    (id: string, value: FlowSettingType['value']) => {
      setLocalSettings(prevSettings => {
        return prevSettings.map(setting => {
          if (setting.id === id) {
            return {
              ...setting,
              value: value as any,
            };
          }

          return setting;
        });
      });
    },
    [],
  );

  const onSaveLocal = useCallback(() => {
    localSettings.forEach(setting => {
      updateSettingValue(setting.id, setting.value);
    });
    navigate('..');
  }, [localSettings]);

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings.length]);

  if (!settings.length) {
    return <Navigate to=".." replace />;
  }

  return (
    <>
      <ModalHeader>
        <div className="flex w-full text-base font-medium">
          <Trans>Fine-tune Campaign</Trans>
        </div>
      </ModalHeader>
      <ModalBody className="mx-auto w-full max-w-lg gap-8 p-5">
        {hasChanged ? (
          <Button
            aria-label={_(msg`Revert settings to recommended`)}
            data-amp-track-label="Revert settings to recommended"
            style={{
              background:
                'linear-gradient(90deg, rgba(71, 78, 255, 0.25) 0%, rgba(148, 63, 255, 0.25) 100%)',
            }}
            className="w-full p-3 text-xs text-foreground/75"
            onPress={() => {
              revertSettings();
              navigate('..');
            }}>
            <Stars02Icon className="h-4 w-4" />
            <div>
              <Trans>{'<- Revert to Recommendation'}</Trans>
            </div>
          </Button>
        ) : null}
        {localSettings.map(setting => {
          return (
            <FlowSetting
              key={setting.id}
              setting={setting}
              settings={localSettings}
              onSettingChange={updateLocalSetting}
            />
          );
        })}
        <TrackButton
          aria-label={_(msg`Save fine-tuned settings`)}
          data-amp-track-label="Save fine-tuned settings"
          className="mt-8 w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
          isDisabled={!localHasChanged}
          onPress={onSaveLocal}>
          <Trans>Save</Trans>
        </TrackButton>
      </ModalBody>
    </>
  );
});

FineTuneCampaignSettings.displayName = 'FineTuneCampaignSettings';

export default FineTuneCampaignSettings;
