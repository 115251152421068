'use client';

import {gql} from '@/__generated__';

export const INIT_SIGNUP = gql(`
  mutation InitSignup($input: InitSignupInput!) {
    initSignup(input: $input)
  }
`);

export const REQUEST_RESET_PASSWORD = gql(`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`);

export const GENERATE_DOWNLOAD_LINK = gql(`
  mutation GenerateFileLink($name: String!, $displayName: String) {
    generateFileLink(name: $name, displayName: $displayName)
  }
`);

export const GET_FILE_BASE64 = gql(`
  mutation GetFileBase64($id: ID!) {
    getFileBase64(id: $id)
  }
`);

export const UPLOAD_IMAGE = gql(`
  mutation UploadFile(
    $input: FileUploadInput!
  ) {
    file: uploadFile(
      input: $input
    ) {
      ...FileFragment
    }
  }
`);

export const RUN_FLOW = gql(`
  mutation RunFlow($input: RunFlowInput!) {
    runFlow(input: $input) {
      id
      userId
      sourceFileId
      status
      errorMessage
      errorStep
      promptCategory
      promptText
      createdAt
      updatedAt
      deletedAt
      campaignId
      campaignCreatedAt
      videoGenerationStartedAt
      sourceFile {
        ...FileFragment
      }
      assets(first: 20) {
        edges {
          node {
            ...AssetFragment
            videoFile {
              ...FileFragment
            }
            videoThumbnailFile {
              name
            }
          }
        }
      }
      settings(first: 100) {
        edges {
          node {
            id
            flowRunId
            platform
            key
            value
            required
            featured
            data
            label
            type
            sortValue
          }
        }
      }
    }
  }
`);

export const UPDATE_ASSET = gql(`
  mutation UpdateAsset($id: ID!, $input: FlowRunAssetUpdateInput!) {
    updateAsset(id: $id, input: $input) {
      ...AssetFragment
      videoFile {
        ...FileFragment
      }
      videoThumbnailFile {
        name
      }
    }
  }
`);

export const CREATE_CAMPAIGN = gql(`
  mutation RunCampaign($input: RunCampaignInput!) {
    runCampaign(input: $input) {
      id
      userId
      sourceFileId
      status
      errorMessage
      errorStep
      promptCategory
      promptText
      campaignId
      createdAt
      updatedAt
      deletedAt
      campaignId
      campaignCreatedAt
      videoGenerationStartedAt
      sourceFile {
        ...FileFragment
      }
      assets(first: 20) {
        edges {
          node {
            ...AssetFragment
            videoFile {
              ...FileFragment
            }
            videoThumbnailFile {
              name
            }
          }
        }
      }
      settings(first: 100) {
        edges {
          node {
            id
            flowRunId
            platform
            key
            value
            required
            featured
            data
            label
            type
            sortValue
          }
        }
      }
    }
  }
`);

export const CONNECT_META_ADS = gql(`
  mutation ConnectMetaAdsIntegration(
    $input: MetaAdsIntegrationConnectInput!
  ) {
    href: connectMetaAdsIntegration(input: $input)
  }
`);

export const META_LOGIN = gql(`
  mutation MetaLogin {
    href: metaLogin
  }
`);

export const CLEAR_FLOW_RUN = gql(`
  mutation ClearRunFlow($input: ClearFlowRunInput!) {
    clearFlowRun(input: $input) {
      id
      userId
      sourceFileId
      status
      errorMessage
      errorStep
      promptCategory
      promptText
      createdAt
      updatedAt
      deletedAt
      campaignId
      campaignCreatedAt
      videoGenerationStartedAt
      sourceFile {
        ...FileFragment
      }
      assets(first: 20) {
        edges {
          node {
            ...AssetFragment
            videoFile {
              ...FileFragment
            }
            videoThumbnailFile {
              name
            }
          }
        }
      }
      settings(first: 100) {
        edges {
          node {
            id
            flowRunId
            platform
            key
            value
            required
            featured
            data
            label
            type
            sortValue
          }
        }
      }
    }
  }
`);

export const UPSERT_META_PRESET = gql(`
  mutation UpsertMetaPreset($input: MetaPresetUpsertInput!, $verify: Boolean) {
    upsertMetaPreset(input: $input, verify: $verify) {
      ...MetaPresetFragment
    }
  }
`);

export const SET_DEFAULT_META_PRESET = gql(`
  mutation SetDefaultMetaPreset($id: ID!) {
    setDefaultMetaPreset(id: $id) {
      ...MetaPresetFragment
    }
  }
`);

export const GENERATE_WHATSAPP_AUTH_INFO = gql(`
  mutation GenerateWhatsappAuthInfo {
    generateWhatsappAuthInfo {
      code
      qrCode
      linkUrl
      expiresAt
    }
  }
`);

export const UPDATE_WHATSAPP_OPTED_IN_PHONE_NUMBER = gql(`
  mutation UpdateWhatsappOptedInPhoneNumber($phoneNumber: String) {
    updateWhatsappOptedInPhoneNumber(phoneNumber: $phoneNumber) {
      id
      createdAt
      updatedAt
      deletedAt
      userId
      phoneNumber
      whatsappOptedIn
    }
  }
`);

export const REGENERATE_FLOW_RUN_ASSET_TEXT = gql(`
  mutation RegenerateFlowRunAssetText(
    $input: RegenerateFlowRunAssetTextInput!
  ) {
    regenerateFlowRunAssetText(input: $input) {
      ...AssetFragment
      videoThumbnailFile {
        name
      }
    }
  }
`);

export const REGENERATE_FLOW_RUN_ASSET_VIDEO = gql(`
  mutation RegenerateFlowRunAssetVideo($id: ID!) {
    regenerateFlowRunAssetVideo(id: $id) {
      ...AssetFragment
      videoThumbnailFile {
        name
      }
    }
  }
`);
