import {FlowPlatform} from '@/__generated__/graphql';
import {getIntegrationIcon} from '@/common/integrations';
import {
  COUNT_PLATFORM_USERS,
  GET_META_PRESET_CONNECTION,
} from '@/common/queries';
import TrackButton from '@/components/buttons/TrackButton';
import PlusIcon from '@/components/icons/PlusIcon';
import MetaPresetItem from '@/components/MetaPresetItem';
import {ModalBody, ModalHeader} from '@/components/Modal';
import ModalOutlet from '@/components/ModalOutlet';
import PortalSpinner from '@/components/PortalSpinner';
import {useAuthContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Checkbox} from '@nextui-org/react';
import {memo, ReactNode, useEffect, useMemo, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const subpath = ['edit'];
const modalClassNames = {wrapper: 'z-[1200]'};

interface Props {
  title: string | ReactNode;
  basePath: string;
  isSaving: boolean;
  initialSelectedPreset?: string | null;
  onSave: (selectedPreset: string | null) => void;
}

const MetaPresets = memo(
  ({title, basePath, isSaving, initialSelectedPreset, onSave}: Props) => {
    const {_} = useLingui();
    const {user} = useAuthContext();
    const [selectedPreset, setSelectedPreset] = useState<string | null>(
      initialSelectedPreset ?? null,
    );
    const [selectedPresetChanged, setSelectedPresetChanged] = useState(false);
    const platformUsersQuery = useQuery(COUNT_PLATFORM_USERS, {
      variables: {
        filter: {
          platform: FlowPlatform.Meta,
          userId: user?.id,
        },
      },
      skip: !user?.id,
    });
    const {data, loading, fetchMore} = useQuery(GET_META_PRESET_CONNECTION, {
      variables: {
        first: 20,
        order: {
          isDefault: 'desc',
          name: 'asc',
        },
      },
    });
    const pageInfo = data?.connection?.pageInfo;
    const showUser = useMemo(
      () => (platformUsersQuery.data?.countPlatformUsers ?? 0) > 1,
      [platformUsersQuery.data],
    );

    useEffect(() => {
      if (pageInfo?.hasNextPage) {
        fetchMore({variables: {after: pageInfo.endCursor}});
      }
    }, [pageInfo?.hasNextPage, pageInfo?.endCursor, fetchMore]);

    useEffect(() => {
      if (!data?.connection || selectedPreset) {
        return;
      }

      const selected = data.connection.edges.find(({node}) => node.isDefault);

      if (selected) {
        setSelectedPreset(selected.node.id);
      }
    }, [data]);

    return (
      <>
        <ModalOutlet
          classNames={modalClassNames}
          basePath={basePath}
          subPath={subpath}
        />
        <ModalHeader>
          <div className="flex w-full items-center gap-3 text-base font-medium">
            <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-[0.625rem] bg-[rgba(0,140,255,0.15)]">
              <img
                src={getIntegrationIcon('meta_ads', 'small')}
                alt="Meta"
                className="h-6 w-6"
              />
            </div>
            {title}
          </div>
        </ModalHeader>
        <ModalBody className="grow flex-col gap-4">
          {!data && loading ? (
            <div className="flex w-full grow items-center justify-center py-4">
              <PortalSpinner />
            </div>
          ) : (
            data?.connection?.edges.map(({node}) => (
              <div
                key={node.id}
                className="flex w-full flex-col gap-4 rounded-2xl bg-foreground/[0.07] p-4">
                <div className="flex w-full items-center gap-2 border-b border-b-foreground/10 pb-3">
                  <div className="flex w-full grow items-center gap-3">
                    <Checkbox
                      aria-label={_(msg`Preset ${node.name} checkbox`)}
                      data-amp-track-label={`Preset checkbox`}
                      isSelected={selectedPreset === node.id}
                      className="m-0 px-0 py-1 [&>*]:text-small"
                      classNames={{
                        icon: 'text-background',
                        wrapper: 'mr-0',
                      }}
                      radius="full"
                      size="lg"
                      onValueChange={() => {
                        if (selectedPreset === node.id) {
                          return;
                        }

                        setSelectedPreset(node.id);
                        setSelectedPresetChanged(true);
                      }}
                    />
                    <div className="flex w-full flex-col">
                      <div className="text-base font-semibold text-foreground">
                        {node.name || node.pageName}
                      </div>
                      {node.isDefault ? (
                        <div className="text-sm text-foreground/50">
                          <Trans>Default</Trans>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Button
                    aria-label={_(msg`Edit preset`)}
                    data-amp-track-label="Edit preset"
                    as={RouterLink}
                    to={`edit/${node.id}`}
                    disableAnimation
                    disableRipple
                    className="min-h-auto h-auto w-auto min-w-0 shrink-0 bg-transparent p-0 text-sm text-foreground/50">
                    <Trans>Edit</Trans>
                  </Button>
                </div>
                <MetaPresetItem item={node} showUser={showUser} />
              </div>
            ))
          )}
          <Button
            aria-label={_(msg`Add Preset`)}
            data-amp-track-label="Add Preset"
            as={RouterLink}
            to={`edit`}
            startContent={
              <PlusIcon className="h-4 w-4 text-foreground" aria-hidden />
            }
            className="w-full shrink-0 border border-foreground/10 bg-transparent px-4 py-2 text-sm font-medium text-foreground">
            <Trans>Add Preset</Trans>
          </Button>
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(25, 25, 26, 0.00) 0%, #19191A 100%)',
            }}
            className="sticky -bottom-5 z-50 -mx-4 -mb-5 flex min-w-[calc(100%_+_1rem)] flex-col gap-3 p-4 md:-bottom-9 md:-mx-8 md:-mb-9 md:min-w-[calc(100%_+_2rem)] md:p-8">
            <TrackButton
              aria-label={_(msg`Save default preset`)}
              data-amp-track-label="Save default preset"
              isLoading={isSaving}
              isDisabled={!selectedPreset || !selectedPresetChanged}
              className="w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground !opacity-100"
              onPress={() => onSave(selectedPreset)}>
              <Trans>Save</Trans>
            </TrackButton>
          </div>
        </ModalBody>
      </>
    );
  },
);

MetaPresets.displayName = 'MetaPresets';

export default MetaPresets;
