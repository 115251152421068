import TrackButton from '@/components/buttons/TrackButton';
import {ButtonProps} from '@nextui-org/button';
import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';

type Props = {
  icon: string;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
  state: 'standby' | 'active' | 'connected';
  isDisabled: boolean;
  text: string;
  ariaLabel: string;
} & ButtonProps;

const OnboardingButton = memo(
  ({
    icon,
    iconStyle,
    iconClassName,
    state,
    isDisabled,
    text,
    ariaLabel,
    className,
    ...restProps
  }: Props) => {
    return (
      <TrackButton
        aria-label={ariaLabel}
        data-amp-track-label={ariaLabel}
        fullWidth
        variant="solid"
        color="primary"
        radius="sm"
        isDisabled={state !== 'active' || isDisabled}
        disableRipple
        style={
          state === 'active'
            ? {
                background:
                  'linear-gradient(314deg, #FFF 4.53%, #D4E2FF 116.86%, #AA9BFF 163.74%)',
                boxShadow:
                  '-1.44px 1.44px 5px 0px rgba(255, 255, 255, 0.75) inset, 1.08px 1.44px 8.641px 0px #FFF inset, -3.24px 3.24px 5.04px 0px rgba(255, 255, 255, 0.65) inset, 0px 8.641px 20px 0px rgba(0, 16, 163, 0.15) inset',
              }
            : {
                background: 'rgba(255, 255, 255, 0.15)',
              }
        }
        className={twMerge(
          'h-auto items-center gap-2 text-sm font-medium text-foreground !opacity-100 transition-all',
          state === 'active'
            ? 'h-[4rem] w-full max-w-[28rem] rounded-[1.25rem] px-4 py-3'
            : 'h-[2rem] w-auto rounded-full px-3 py-1',
          className,
        )}
        {...restProps}>
        <img
          src={icon}
          alt={ariaLabel}
          style={iconStyle}
          className={twMerge(
            'h-full max-h-[2rem] w-auto object-contain',
            iconClassName,
          )}
        />
        {state === 'active' ? (
          <span
            className={'text-xl font-bold'}
            style={{
              background: 'linear-gradient(180deg, #1A0739 0%, #353F9E 100%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: 'transparent',
              padding: '0 1px',
            }}>
            {text}
          </span>
        ) : (
          <span className={'text-sm font-normal text-foreground/50'}>
            {state === 'connected' ? (
              <span>
                <Trans>Connected</Trans>
              </span>
            ) : (
              <span>
                <Trans>Next: {text}</Trans>
              </span>
            )}
          </span>
        )}
        {state === 'connected' ? (
          <div
            style={{
              background: 'linear-gradient(180deg, #B2FF00 0%, #00FF7B 100%)',
              boxShadow:
                '-0.5px -0.5px 3px 0px #0EFF72, 0.5px 0.5px 3px 0px rgba(144, 255, 0, 0.50)',
            }}
            className="flex h-[0.625rem] w-[0.625rem] shrink-0 rounded-full"></div>
        ) : null}
      </TrackButton>
    );
  },
);

OnboardingButton.displayName = 'OnboardingButton';

export default OnboardingButton;
