import {memo, useCallback, useEffect, useMemo} from 'react';
import {Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {GeneratedVideoState} from '@/common/types';
import usePresignedLink from '@/hooks/usePresignedLink';
import {FlowContextValue} from '@/contexts/FlowContext';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import PlayIcon from '@/components/icons/PlayIcon';
import VideoSelectorItemSkeleton from './VideoSelectorItemSkeleton';
import CheckRectIcon from '@/components/icons/CheckRectIcon';
import CheckRectEmptyIcon from '@/components/icons/CheckRectEmptyIcon';
import {animate, useMotionValue} from 'framer-motion';
import dayjs from 'dayjs';
import {VIDEOGEN_PRELOAD_TO_ASSET_TIME} from '@/config';
import FlowSpinner from './FlowSpinner';

const VideoSelectorItem = memo(
  ({
    id,
    isSelected,
    isPreload,
    thumbnailName,
    caption,
    musicAuthor,
    musicTitle,
    containerRef,
    createdAt,
    updateGeneratedVideoState,
  }: GeneratedVideoState & {
    isLast: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
    updateGeneratedVideoState: FlowContextValue['updateGeneratedVideoState'];
  }) => {
    const {_} = useLingui();
    const thumbnailURL = usePresignedLink(thumbnailName);
    const loaderStartTS = useMemo(
      () =>
        dayjs(createdAt.endsWith('Z') ? createdAt : createdAt + 'Z').valueOf(),
      [createdAt],
    );
    const calculateProgress = useCallback(() => {
      return Math.min(
        100,
        Math.max(
          0,
          ((Date.now() - loaderStartTS) / VIDEOGEN_PRELOAD_TO_ASSET_TIME) * 100,
        ),
      );
    }, [loaderStartTS]);
    const initialProgress = useMemo(() => calculateProgress(), []);
    const loaderProgressValue = useMotionValue(initialProgress);
    let musicParts = [musicTitle, musicAuthor].filter(Boolean);

    if (musicParts.length === 2) {
      musicParts = [musicParts[0], 'by', musicParts[1]];
    }

    useEffect(() => {
      if (!isPreload) {
        return;
      }

      const interval = setInterval(() => {
        animate(loaderProgressValue, calculateProgress(), {
          duration: 0.9,
          ease: 'linear',
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [isPreload, calculateProgress]);

    return (
      <div ref={containerRef} className="relative">
        <Button
          aria-label={_(msg`Preview video`)}
          data-amp-track-label="Preview video"
          as={RouterLink}
          to={`asset/edit/${id}`}
          disableAnimation
          disableRipple
          isIconOnly
          isDisabled={isPreload}
          className="block h-auto max-h-none min-h-0 w-full min-w-0 max-w-none rounded-xl bg-transparent p-0 text-foreground !opacity-100">
          {thumbnailURL ? (
            <img
              src={thumbnailURL}
              alt={caption ?? 'Video thumbnail'}
              className="block h-auto min-h-[6rem] w-full"
            />
          ) : (
            <VideoSelectorItemSkeleton className="aspect-[9/16]" />
          )}
        </Button>
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          {isPreload ? (
            <>
              <FlowSpinner
                size={64}
                strokeWidth={5}
                spin={false}
                fillColor={'#fff'}
                backgroundColor={'rgba(255,255,255,0.3)'}
                progress={loaderProgressValue}
              />
            </>
          ) : (
            <>
              <PlayIcon className="h-10 w-10 text-foreground" />
              <Button
                aria-label={_(msg`Toggle video selection`)}
                data-amp-track-label="Toggle video selection"
                disableAnimation
                disableRipple
                isIconOnly
                className="pointer-events-auto absolute right-0 top-0 z-10 h-auto min-h-0 w-auto min-w-0 rounded-none bg-transparent p-1"
                onPress={() =>
                  updateGeneratedVideoState({
                    id,
                    data: {isSelected: !isSelected},
                  })
                }>
                {isSelected ? (
                  <CheckRectIcon className="h-6 w-6 text-primary" />
                ) : (
                  <CheckRectEmptyIcon className="h-6 w-6 text-primary" />
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  },
);

VideoSelectorItem.displayName = 'VideoSelectorItem';

export default VideoSelectorItem;
