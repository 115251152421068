'use client';

import {useMemo} from 'react';
import {fromPromise, ApolloClient, InMemoryCache, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {API_URL} from '../config';
import {relayStylePagination} from '@apollo/client/utilities';
import {fetchNewToken, getAccessToken} from '@/utils';
import {useToastQueue} from './useToastQueue';
import {useAnalyticsContext, useAppContext} from './useContexts';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const httpLink = from([
  new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  }),
  createUploadLink({uri: `${API_URL}/graphql`}),
]);
const connectionKeyArgs = ['filter', 'order'];
const queryConnections = [
  'getUserConnection',
  'getIntegrationConnection',
  'getFlowRunAssetConnection',
  'getPhoneNumberConnection',
  'getPlatformUserConnection',
  'getMetaPresetConnection',
  'getFlowRunConnection',
];
const cache = new InMemoryCache({
  typePolicies: {
    Asset: {keyFields: ['url']},
    ToolSetupStep: {keyFields: ['name']},
    Language: {keyFields: ['iso2']},
    DataSource: {keyFields: ['type', 'name']},
    RichText: {keyFields: ['markdown']},
    ExplainButton: {keyFields: ['content', 'prompt']},
    Query: {
      fields: Object.fromEntries(
        queryConnections.map(c => [c, relayStylePagination(connectionKeyArgs)]),
      ),
    },
  },
});

export const useApollo = () => {
  const {addErrorToast} = useToastQueue();
  const {isAuthenticated, login, logout} = useAppContext();
  const instrumentTracker = useAnalyticsContext();

  const apolloClient = useMemo(() => {
    let client: ApolloClient<any> | undefined; // eslint-disable-line prefer-const

    const authLink = setContext(async (_, {headers}) => {
      const token = getAccessToken();
      const trackerHeaders = await instrumentTracker?.buildHeaders();

      return {
        headers: {
          ...headers,
          ...trackerHeaders,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const errorLink = onError(
      ({graphQLErrors, networkError, operation, forward}) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            switch (err.extensions?.code) {
              case 'UNAUTHORIZED':
                return fromPromise(
                  fetchNewToken(
                    login,
                    logout,
                    client,
                    instrumentTracker.setUserId,
                  ),
                )
                  .filter(Boolean)
                  .flatMap(accessToken => {
                    const oldHeaders = operation.getContext().headers;

                    // modify the operation context with a new token
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: `Bearer ${accessToken}`,
                      },
                    });

                    // retry the request, returning the new observable
                    return forward(operation);
                  });

              default:
                addErrorToast({
                  message: `[GraphQL error]: Message: ${
                    err.message
                  }, Location: ${JSON.stringify(err.locations)}, Path: ${err.path}`,
                });
            }
          }
        }

        // To retry on network errors use the RetryLink instead of the onError link.
        if (networkError) {
          addErrorToast({message: `[Network error]: ${networkError}`});
        }
      },
    );

    client = new ApolloClient({
      link: authLink.concat(errorLink).concat(httpLink),
      cache,
    });

    return client;
  }, [isAuthenticated]);

  return apolloClient;
};
