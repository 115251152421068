import {MotionValue, useTransform, motion} from 'framer-motion';
import {memo} from 'react';

const ProgressSegment = memo(
  ({
    index,
    segmentProgress,
    totalSegments,
  }: {
    index: number;
    segmentProgress: MotionValue<number>;
    totalSegments: number;
  }) => {
    const width = useTransform(segmentProgress, latest => {
      const isPartiallyFilled = Math.floor(latest) === index;
      const partialWidth = (latest % 1) * 100;

      return isPartiallyFilled
        ? partialWidth
        : index < Math.floor(latest)
          ? 100
          : 0;
    });

    const translateX = useTransform(width, w => {
      return `${-100 + w}%`;
    });

    return (
      <div className="relative flex-1">
        <div className="absolute inset-0 rounded-full bg-foreground/25" />

        <div className="absolute inset-0 overflow-hidden rounded-full">
          <motion.div
            className="absolute inset-0 rounded-full"
            style={{
              width: '100%',
              background: 'linear-gradient(90deg, #7378ff, #a966ff)',
              backgroundSize: `${totalSegments * 100}%`,
              backgroundPositionX: `${-index * 100}%`,
              x: translateX,
            }}
          />
        </div>
      </div>
    );
  },
);

ProgressSegment.displayName = 'ProgressSegment';

const SegmentedProgressBar = memo(
  ({
    progress,
    segments,
    gap = 4,
  }: {
    progress: MotionValue<number>;
    segments: number;
    gap?: number;
  }) => {
    const segmentProgress = useTransform(
      progress,
      latest => segments * (Math.min(Math.max(latest, 0), 100) / 100),
    );

    return (
      <div className="relative flex h-1 w-full shrink-0 overflow-hidden rounded-full">
        <div className="absolute inset-0 flex w-full" style={{gap}}>
          {Array.from({length: segments}, (_, index) => (
            <ProgressSegment
              key={index}
              index={index}
              segmentProgress={segmentProgress}
              totalSegments={segments}
            />
          ))}
        </div>
      </div>
    );
  },
);

SegmentedProgressBar.displayName = 'SegmentedProgressBar';

export default SegmentedProgressBar;
