import TriangleSolidIcon from '@/components/icons/TriangleSolidIcon';
import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {memo} from 'react';
import {twJoin} from 'tailwind-merge';

export const MetricTrend = memo(
  ({value, isInverted}: {value?: number | null; isInverted?: boolean}) => {
    const {i18n} = useLingui();

    if (value === null || value === undefined) {
      return (
        <div className="text-sm font-normal text-foreground/50">
          <Trans>No trend</Trans>
        </div>
      );
    }

    const isOkTrend = isInverted ? value <= 0 : value >= 0;

    return (
      <div
        className={twJoin(
          'flex items-center gap-1',
          isOkTrend ? 'text-success' : 'text-danger',
        )}>
        <TriangleSolidIcon
          className={twJoin(
            'h-2 w-2 shrink-0',
            value === 0 ? 'hidden' : value < 0 ? 'rotate-180' : '',
          )}
        />
        <span>
          {i18n.number(value, {
            style: 'percent',
            maximumFractionDigits: 1,
            signDisplay: 'never',
          })}
        </span>
      </div>
    );
  },
);

MetricTrend.displayName = 'MetricTrend';
