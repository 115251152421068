import TrackButton from '@/components/buttons/TrackButton';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import {VIDEOGEN_VIDEO_COUNT} from '@/config';
import {useFlowContext, useResizeObserver} from '@/hooks';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  animate,
  interpolate,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export const useFlowFileUpload = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [canSelectFiles, setCanSelectFiles] = useState(false);
  const {setSelectedImage} = useFlowContext();

  // Handler for file selection
  const handleFileSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];

      if (!file) {
        return;
      }

      // Create a URL for the selected image
      const imageURL = URL.createObjectURL(file);

      setSelectedImage({
        url: imageURL,
        file,
      });
    },
    [setSelectedImage],
  );

  // Functions to trigger the file input with appropriate attributes
  const handleSelectFromFiles = useCallback(() => {
    if (fileInputRef.current) {
      // Reset the input value to allow re-selecting the same file
      fileInputRef.current.value = '';
      fileInputRef.current.accept = 'image/*'; // Accept only images
      fileInputRef.current.removeAttribute('capture'); // Remove capture attribute
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const resetSelectedImage = useCallback(() => {
    setSelectedImage(undefined);
  }, [setSelectedImage]);

  useEffect(() => {
    const canSelectFiles = Boolean(
      window.File && window.FileReader && window.FileList && window.Blob,
    );

    setCanSelectFiles(canSelectFiles);
  }, []);

  return {
    fileInputRef,
    canSelectFiles,
    handleFileSelection,
    handleSelectFromFiles,
    resetSelectedImage,
  };
};

export const useFlowCounts = () => {
  const {run, generatedVideosState} = useFlowContext();
  const finishedVideos = useMemo(() => {
    return generatedVideosState.filter(v => !v.isPreload);
  }, [generatedVideosState]);
  const preloadedVideosCount = useMemo(() => {
    return generatedVideosState.filter(v => v.isPreload).length;
  }, [generatedVideosState]);
  const newGeneratedVideosCount = useMemo(() => {
    return finishedVideos.filter(v => v.isNewlyGenerated).length;
  }, [finishedVideos]);
  const totalGeneratingVideosCount = useMemo(() => {
    if (!run?.videoGenerationStartedAt) {
      return 0;
    }

    return VIDEOGEN_VIDEO_COUNT;
  }, [run?.videoGenerationStartedAt]);
  const generatingVideosCount =
    totalGeneratingVideosCount - newGeneratedVideosCount;

  return {
    newGeneratedVideosCount,
    totalGeneratingVideosCount,
    generatingVideosCount,
    preloadedVideosCount,
  };
};

export const useAssetsVideoContainerAccordion = (videosCount: number) => {
  const buttonHeight = 44;
  const measurementIntervalRef = useRef<any>(null);
  const {_} = useLingui();
  const videosContainerRef = useRef<HTMLDivElement>(null);
  const videosContentMeasureRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isOverflowingValue = useMotionValue(false);
  const videosContainerMinHeightValue = useMotionValue(0);
  const videosContainerMaxHeightValue = useMotionValue(0);
  const videosContainerAnimationValue = useMotionValue(0);
  const buttonOffsetValue = useTransform(() => {
    const isOverflowing = isOverflowingValue.get();

    return isOverflowing ? buttonHeight + 4 : 0;
  });
  const videosContainerInterpolationValue = useTransform(() => {
    const min = videosContainerMinHeightValue.get();
    const max = videosContainerMaxHeightValue.get();
    const button = buttonOffsetValue.get();

    return interpolate([0, 1], [min, max + button]);
  });
  const videosContainerHeightValue = useTransform(() => {
    const lerp = videosContainerInterpolationValue.get();
    const anim = videosContainerAnimationValue.get();

    return lerp(anim);
  });

  const onVideosContainerResize = useCallback(() => {
    if (!videosContentMeasureRef.current) {
      return;
    }

    const rect = videosContentMeasureRef.current.getBoundingClientRect();
    const gap = 4;
    const height = videosContentMeasureRef.current.offsetTop;
    const width = rect.width;
    const itemWidth = (width - gap * 2) / 3;
    const minHeight = itemWidth * 2 * (16 / 9) + buttonHeight + gap;
    const clampedMinHeight = Math.min(minHeight, height);
    const isOverflowing = height > minHeight;

    if (isOverflowing !== isOverflowingValue.get()) {
      setIsOverflowing(isOverflowing);
      isOverflowingValue.set(isOverflowing);
    }

    if (videosContainerMinHeightValue.get() !== clampedMinHeight) {
      videosContainerMinHeightValue.set(clampedMinHeight);
    }

    if (videosContainerMaxHeightValue.get() !== height) {
      videosContainerMaxHeightValue.set(height);
    }
  }, []);

  const onVideoContainerToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    // additional periodic measurement since ResizeObserver is not always called when offsetTop changes
    measurementIntervalRef.current = setInterval(() => {
      onVideosContainerResize();
    }, 100);

    return () => {
      clearInterval(measurementIntervalRef.current);
    };
  }, [onVideosContainerResize]);

  useResizeObserver(videosContentMeasureRef, onVideosContainerResize);

  const button = useMemo(() => {
    return (
      <TrackButton
        aria-label={_(msg`Toggle video container collapse state`)}
        data-amp-track-label="Toggle video container collapse state"
        disableAnimation
        disableRipple
        style={{
          height: buttonHeight,
          background: isOpen
            ? 'transparent'
            : 'linear-gradient(180deg, rgba(39, 39, 39, 0.80) 0%, #272727 100%)',
        }}
        className={twMerge(
          'min-h-auto absolute bottom-0 left-0 right-0 z-50 flex w-full items-center justify-center gap-1 rounded-none border-none text-foreground transition-all',
          isOverflowing ? '' : 'hidden',
        )}
        endContent={
          <ChevronLeftIcon
            className={twMerge(
              'h-4 w-4 transform',
              isOpen ? 'rotate-90' : '-rotate-90',
            )}
          />
        }
        onPress={onVideoContainerToggle}>
        {isOpen ? (
          <Trans>Collapse</Trans>
        ) : (
          <Trans>View all ({videosCount - 6})</Trans>
        )}
      </TrackButton>
    );
  }, [_, isOpen, onVideoContainerToggle, videosCount, isOverflowing]);

  useEffect(() => {
    animate(videosContainerAnimationValue, isOpen ? 1 : 0, {
      duration: 0.2,
      type: 'tween',
    });
  }, [isOpen]);

  return {
    videosContainerRef,
    videosContentMeasureRef,
    isVideoContainerOpen: isOpen,
    isVideoContainerOverflowing: isOverflowing,
    videosContainerHeightValue,
    videosContainerAnimationValue,
    videoContainerToggleButton: button,
    onVideoContainerToggle,
  };
};
