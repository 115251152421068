import {useAnalytics} from '@/common/analytics';
import {getIntegrationIcon} from '@/common/integrations';
import {CONNECT_META_ADS, UPSERT_META_PRESET} from '@/common/mutations';
import {GET_META_PRESET, GET_META_PRESETS_DATA} from '@/common/queries';
import {IntegrationType} from '@/common/types';
import TrackButton from '@/components/buttons/TrackButton';
import AnnotationQuestionIcon from '@/components/icons/AnnotationQuestionIcon';
import {ModalBody, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import useMetaPresetFields from '@/hooks/useMetaPresetFields';
import ErrorBanner from '@/routes/GenerateCampaign/components/ErrorBanner';
import {useMutation, useQuery} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Input} from '@nextui-org/react';
import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const INTEGRATION_TYPE = IntegrationType.metaAds;

const EditMetaPreset = memo(() => {
  const {_} = useLingui();
  const {id} = useParams<{id?: string}>();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [verifyFailed, setVerifyFailed] = useState(false);
  const {reportIntegrationStart, reportIntegration3rdPartyServiceRedirect} =
    useAnalytics();
  const metaPresetDataQuery = useQuery(GET_META_PRESETS_DATA);
  const metaPresetQuery = useQuery(GET_META_PRESET, {
    variables: {
      id: id!,
    },
    skip: !id,
  });
  const isLoading = Boolean(
    (!metaPresetDataQuery.data && metaPresetDataQuery.loading) ||
      (!metaPresetQuery.data && metaPresetQuery.loading && id),
  );
  const [upsertMetaPreset, {loading: upsertPresetLoading}] = useMutation(
    UPSERT_META_PRESET,
    {
      refetchQueries: ['GetMetaPresetConnection'],
      onError: error => {
        if (
          error.graphQLErrors.some(e => e.extensions?.code === 'BAD_REQUEST')
        ) {
          setVerifyFailed(true);
        }
      },
      onCompleted: () => {
        navigate('..');
      },
    },
  );
  const [connect, {loading: connectLoading}] = useMutation(CONNECT_META_ADS, {
    onCompleted: data => {
      reportIntegration3rdPartyServiceRedirect(
        INTEGRATION_TYPE,
        data.href,
      ).finally(() => {
        window.location.href = data.href;
      });
    },
  });
  const {fields, state, inputData} = useMetaPresetFields(
    metaPresetDataQuery.data?.getMetaPresetData ?? null,
    metaPresetQuery.data?.getMetaPreset ?? null,
  );
  const hasMissingFields = useMemo(
    () =>
      Object.values(state.validation).length
        ? Object.values(state.validation).some(f => f.isMissing)
        : false,
    [state.validation],
  );

  const onSave = useCallback(() => {
    if (upsertPresetLoading || !inputData) {
      return;
    }

    setVerifyFailed(false);

    upsertMetaPreset({
      variables: {
        input: {
          ...(id ? {id} : {}),
          name,
          ...inputData,
        },
        verify: true,
      },
    });
  }, [upsertMetaPreset, upsertPresetLoading, id, name, inputData]);

  useEffect(() => {
    if (metaPresetQuery.data?.getMetaPreset) {
      setName(metaPresetQuery.data.getMetaPreset.name ?? '');
    }
  }, [metaPresetQuery.data]);

  return (
    <>
      <ModalHeader>
        <div className="flex w-full items-center gap-3 text-base font-medium">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-[0.625rem] bg-[rgba(0,140,255,0.15)]">
            <img
              src={getIntegrationIcon('meta_ads', 'small')}
              alt="Meta"
              className="h-6 w-6"
            />
          </div>
          <Trans>Edit Meta Ads Preset</Trans>
        </div>
      </ModalHeader>
      <ModalBody className="flex-col items-center gap-8">
        {isLoading ? (
          <div className="p-4">
            <PortalSpinner />
          </div>
        ) : (
          <>
            <div className="flex w-full flex-col gap-2">
              <div className="text-sm text-foreground/50">
                <Trans>Setup Name (Optional)</Trans>
              </div>
              <Input
                fullWidth
                maxLength={50}
                placeholder={_(msg`Enter a name`)}
                classNames={{
                  inputWrapper:
                    'bg-foreground/10 group-data-[focus=true]:bg-foreground/10 group-data-[hover=true]:bg-foreground/10',
                }}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            {fields}
            <div className="flex gap-4 rounded-2xl border border-foreground/10 p-4">
              <AnnotationQuestionIcon className="h-6 w-6 text-foreground/50" />
              <div className="flex grow flex-col gap-1">
                <div className="text-sm text-foreground">
                  <Trans>Can’t find the right page or account?</Trans>
                </div>
                <div className="text-sm text-foreground/50">
                  <Trans>
                    <Button
                      aria-label={`Try signing up with Meta`}
                      data-amp-track-label={`Try signing up with Meta`}
                      disableAnimation
                      disableRipple
                      className="h-auto min-h-0 w-auto min-w-0 rounded-none bg-transparent p-0 text-foreground/50 underline"
                      onPress={() => {
                        if (connectLoading) {
                          return;
                        }

                        reportIntegrationStart(INTEGRATION_TYPE).finally(() => {
                          connect({
                            variables: {
                              input: {
                                callbackPath:
                                  location.pathname.slice(1) + location.search,
                              },
                            },
                          });
                        });
                      }}>
                      Try signing up with Meta
                    </Button>{' '}
                    again and enable the missing options.
                  </Trans>
                </div>
              </div>
            </div>
            <ErrorBanner
              isShown={hasMissingFields}
              title={_(msg`Missing preset fields`)}
              message={_(
                msg`We were unable to get some of the fields that are stored in the preset. Make sure you have the access to the needed Pages and Ad accounts.`,
              )}
            />
            <ErrorBanner
              isShown={verifyFailed}
              title={_(msg`Campaign Creation Failed`)}
              message={_(
                msg`Make sure you’ve selected the correct page, ad account and pixel combination, then try again.`,
              )}
            />
            <TrackButton
              aria-label={_(msg`Edit preset`)}
              data-amp-track-label="Edit preset"
              className="mt-3 w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
              isDisabled={!inputData}
              isLoading={upsertPresetLoading}
              onPress={onSave}>
              <Trans>Save</Trans>
            </TrackButton>
          </>
        )}
      </ModalBody>
    </>
  );
});

EditMetaPreset.displayName = 'EditMetaPreset';

export default EditMetaPreset;
