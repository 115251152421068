import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import StepContainer from '../components/StepContainer';
import {useFlowContext} from '@/hooks';
import {AnimatePresence, motion} from 'framer-motion';
import FlowSpinner from '../components/FlowSpinner';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import ConfirmationSVG from '@/components/icons/flow/confirmation.svg';
import VideoSelectorItem from '../components/VideoSelectorItem';
import VideoSelectorItemSkeleton from '../components/VideoSelectorItemSkeleton';
import FlowSetting from '@/components/FlowSetting';
import RecommendedSettings from '../components/RecommendedSettings';
import TrackButton from '@/components/buttons/TrackButton';
import {useApolloClient, useMutation} from '@apollo/client';
import {CREATE_CAMPAIGN, UPSERT_META_PRESET} from '@/common/mutations';
import {useNavigate} from 'react-router-dom';
import {Modal, ModalBody, ModalContent} from '@/components/Modal';
import SuccessMeasured from '../components/SuccessMeasured';
import {useAnalytics} from '@/common/analytics';
import {FlowSetting as FlowSettingType, IntegrationType} from '@/common/types';
import {GET_FLOW_RUN} from '@/common/queries';
import ErrorBanner from '../components/ErrorBanner';
import {FlowRunErrorStep} from '@/__generated__/graphql';
import VideoRecorderOffIcon from '@/components/icons/VideoRecorderOffIcon';
import {useInView} from 'react-intersection-observer';
import ProgressOverlay from '../components/ProgressOverlay';
import {useAssetsVideoContainerAccordion, useFlowCounts} from '../hooks';

const ConfirmCampaign = memo(() => {
  const {
    ref: assetsSectionRef,
    inView,
    entry: videoContainerElement,
  } = useInView();
  const apolloClient = useApolloClient();
  const {reportLaunchAdCampaignStart} = useAnalytics();
  const {_, i18n} = useLingui();
  const navigate = useNavigate();
  const {
    loaderProgress,
    generatedVideosState,
    run,
    runId,
    isGeneratingVideos,
    settings,
    errorStep,
    metaPresetToCreate,
    updateGeneratedVideoState,
    updateSettingValue,
  } = useFlowContext();
  const [missingRequiredFields, setMissingRequiredFields] = useState<string[]>(
    [],
  );
  const [createCampaign, createCampaignData] = useMutation(CREATE_CAMPAIGN, {
    update(cache, {data}) {
      if (!data) {
        return;
      }

      cache.writeQuery({
        query: GET_FLOW_RUN,
        variables: {id: data.runCampaign.id},
        data: {getFlowRun: data.runCampaign},
      });
    },
  });
  const [upsertMetaPreset, {loading: upsertPresetLoading}] = useMutation(
    UPSERT_META_PRESET,
    {
      refetchQueries: ['GetMetaPresetConnection'],
    },
  );
  const isLoading = createCampaignData.loading || upsertPresetLoading;
  const hasVideos = generatedVideosState.length > 0;
  const {
    newGeneratedVideosCount,
    totalGeneratingVideosCount,
    generatingVideosCount,
    preloadedVideosCount,
  } = useFlowCounts();
  const itemTitleStyle = 'text-sm text-foreground/50';
  const featuredSettings = useMemo(() => {
    return settings.filter(setting => setting.featured);
  }, [settings]);
  const fineTuneSettings = useMemo(() => {
    return settings.filter(setting => !setting.featured);
  }, [settings]);
  const validateSettings = useCallback((inputSettings: FlowSettingType[]) => {
    const missingRequiredFields = inputSettings.filter(
      s => s.required && (Array.isArray(s.value) ? !s.value.length : !s.value),
    );

    if (missingRequiredFields.length) {
      setMissingRequiredFields(missingRequiredFields.map(s => s.label));

      return false;
    }

    return true;
  }, []);
  const launchCampaign = useCallback(async () => {
    let inputSettings = [...settings];

    if (metaPresetToCreate) {
      const {data} = await upsertMetaPreset({
        variables: {
          input: {
            ...metaPresetToCreate,
            isDefault: true,
          },
        },
      });

      inputSettings = inputSettings.map(setting => {
        if (setting.key === 'meta_preset') {
          return {
            ...setting,
            value: data?.upsertMetaPreset.id,
          };
        }

        return setting;
      });
    }

    if (!validateSettings(inputSettings)) {
      return;
    }

    const selectedAssetsIds = generatedVideosState
      .filter(v => v.isSelected)
      .map(v => v.id);

    try {
      await reportLaunchAdCampaignStart({
        '3rd_party_service': IntegrationType.metaAds,
        flow_run_id: runId!,
        number_of_assets: selectedAssetsIds.length,
        ...Object.fromEntries(
          inputSettings.map(s => [`setting_${s.key}`, JSON.stringify(s.value)]),
        ),
      });
    } catch (e) {
      console.error(e);
    } finally {
      await createCampaign({
        variables: {
          input: {
            flowRunId: runId,
            settings: inputSettings.map(setting => ({
              id: setting.id,
              value: setting.value,
            })),
            selectedAssetsIds,
          },
        },
        onCompleted: data => {
          // evict cache to refetch the new assets
          apolloClient.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'getFlowRunAssetConnection',
          });
          apolloClient.cache.gc();

          if (data.runCampaign && !data.runCampaign.errorStep) {
            navigate(`/create/${data.runCampaign.id}/success`);
          }
        },
      }).catch(() => null);
    }
  }, [
    runId,
    settings,
    generatedVideosState,
    metaPresetToCreate,
    createCampaign,
    navigate,
    validateSettings,
    reportLaunchAdCampaignStart,
    upsertMetaPreset,
  ]);

  const scrollToVideos = useCallback(() => {
    videoContainerElement?.target?.scrollIntoView({behavior: 'smooth'});
  }, [videoContainerElement]);

  const areVideosAscending = useMemo(() => {
    return generatedVideosState.every(video => video.isNewlyGenerated);
  }, [generatedVideosState]);

  const videosWithPreloaders = useMemo(() => {
    const preloaders = isGeneratingVideos
      ? new Array(
          Math.max(0, generatingVideosCount - preloadedVideosCount),
        ).fill(1)
      : [];

    if (areVideosAscending) {
      return [...generatedVideosState, ...preloaders];
    }

    return [...preloaders, ...generatedVideosState];
  }, [
    areVideosAscending,
    generatedVideosState,
    generatingVideosCount,
    preloadedVideosCount,
    isGeneratingVideos,
  ]);

  const {
    videosContainerRef,
    videosContentMeasureRef,
    videosContainerHeightValue,
    videoContainerToggleButton,
  } = useAssetsVideoContainerAccordion(videosWithPreloaders.length);

  const selectedCount = useMemo(() => {
    return generatedVideosState.filter(v => v.isSelected).length;
  }, [generatedVideosState]);

  useEffect(() => {
    if (!missingRequiredFields.length) {
      return;
    }

    const timeout = setTimeout(() => {
      setMissingRequiredFields([]);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [missingRequiredFields]);

  return (
    <>
      <Modal
        size="xs"
        radius="none"
        className="min-h-32 rounded-3xl bg-background/75 backdrop-blur-xl"
        placement="center"
        hideCloseButton
        isOpen={missingRequiredFields.length > 0}
        onOpenChange={() => setMissingRequiredFields([])}>
        <ModalContent className="min-w-64">
          <ModalBody className="flex-col items-center justify-center gap-2 p-4 text-foreground">
            <div className="text-xl font-semibold">
              Some of the fields are not filled
            </div>
            <ul className="flex w-full list-disc flex-col gap-2 pl-4 text-sm font-normal text-foreground marker:text-foreground">
              {missingRequiredFields.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ProgressOverlay
        scrollToVideos={scrollToVideos}
        isVisible={!inView && Boolean(videoContainerElement)}
      />
      <StepContainer
        step="configure_campaign"
        isCompleted={false}
        title={_(msg`Confirm Campaign`)}
        titleCompleted={_(msg`Confirm Campaign`)}
        icon={
          <img className="h-7 w-7" src={ConfirmationSVG} alt="Confirmation" />
        }
        iconCompleted={
          <img className="h-7 w-7" src={ConfirmationSVG} alt="Confirmation" />
        }
        subtitle={_(msg`~2 minutes`)}>
        <div className="flex w-full flex-col gap-8">
          <div ref={assetsSectionRef} className="flex w-full flex-col gap-4">
            <div className="flex items-center justify-between gap-2">
              <div className={itemTitleStyle}>
                <Trans>Select Assets</Trans>
              </div>
              <AnimatePresence>
                {totalGeneratingVideosCount > 0 &&
                generatedVideosState.length &&
                isGeneratingVideos ? (
                  <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{
                      exit: {
                        delay: 2,
                      },
                    }}
                    className="flex items-center gap-2">
                    <div className="text-sm text-foreground/50">
                      <Trans>
                        {newGeneratedVideosCount} out of{' '}
                        {totalGeneratingVideosCount}
                      </Trans>
                    </div>
                    <FlowSpinner
                      progress={
                        (newGeneratedVideosCount / totalGeneratingVideosCount) *
                        100
                      }
                      size={24}
                      strokeWidth={4}
                      spin={false}
                      className="h-6 w-6"
                    />
                  </motion.div>
                ) : !isGeneratingVideos ? (
                  <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{
                      exit: {
                        delay: 2,
                      },
                    }}
                    className="text-sm text-foreground">
                    <Trans>{selectedCount} Selected</Trans>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
            {!hasVideos ? (
              <div className="flex w-full flex-col">
                <div
                  style={{
                    background:
                      'linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.03) 100%)',
                  }}
                  className="flex h-[10rem] w-full items-center justify-center rounded-[0.875rem]">
                  {errorStep === FlowRunErrorStep.GeneratingVideos ? (
                    <VideoRecorderOffIcon className="flex h-16 w-16 shrink-0 text-foreground/15" />
                  ) : loaderProgress != null ? (
                    <motion.div
                      key="loader"
                      className="m-auto flex flex-col items-center gap-2"
                      initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      exit={{opacity: 0}}>
                      <div className="relative flex flex-col gap-2">
                        <FlowSpinner
                          progress={loaderProgress}
                          size={80}
                          strokeWidth={5}
                          className="h-20 w-20"
                        />
                        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-lg font-semibold">
                          {i18n.number(loaderProgress / 100, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                          })}
                        </div>
                      </div>
                      <div className="text-center text-sm">
                        {loaderProgress === 100 ? (
                          <Trans>Finishing Generation...</Trans>
                        ) : (
                          <Trans>Generating...</Trans>
                        )}
                      </div>
                    </motion.div>
                  ) : null}
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(0deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.00) 100%)',
                  }}
                  className="mx-[1rem] flex h-5 w-[calc(100%-2rem)] items-center justify-center rounded-b-[0.875rem]"
                />
                <div
                  style={{
                    background:
                      'linear-gradient(0deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.00) 100%)',
                  }}
                  className="mx-[2rem] flex h-4 w-[calc(100%-4rem)] items-center justify-center rounded-b-[0.875rem]"
                />
              </div>
            ) : (
              <motion.div
                style={{
                  height: videosContainerHeightValue,
                }}
                className="relative flex w-full overflow-hidden">
                <div
                  ref={videosContainerRef}
                  className="flex w-full shrink-0 grow-0 flex-col">
                  <div className="grid w-full shrink-0 auto-rows-auto grid-cols-3 gap-1">
                    {videosWithPreloaders.map((videoOrPreloader, idx) => {
                      return typeof videoOrPreloader === 'number' ? (
                        <VideoSelectorItemSkeleton
                          className="aspect-[9/16]"
                          key={idx}
                        />
                      ) : (
                        <VideoSelectorItem
                          key={videoOrPreloader.id}
                          isLast={idx === videosWithPreloaders.length - 1}
                          {...videoOrPreloader}
                          updateGeneratedVideoState={updateGeneratedVideoState}
                        />
                      );
                    })}
                  </div>
                  <div ref={videosContentMeasureRef} className="h-0 w-full" />
                </div>
                {videoContainerToggleButton}
              </motion.div>
            )}
          </div>
          <div className="flex flex-col gap-8 empty:hidden">
            {featuredSettings.map(setting => {
              return (
                <FlowSetting
                  key={setting.id}
                  setting={setting}
                  settings={featuredSettings}
                  onSettingChange={updateSettingValue}
                />
              );
            })}
          </div>
          <RecommendedSettings settings={fineTuneSettings} />
          <SuccessMeasured />
          <ErrorBanner
            isShown={errorStep === FlowRunErrorStep.CampaginCreation}
            title={_(msg`Campaign Creation Failed`)}
            message={_(
              msg`The selected Meta Ads setup won’t work. Adjust the combination to successfully launch your ads.`,
            )}
          />
          <TrackButton
            aria-label={_(msg`Launch Campaign`)}
            data-amp-track-label="Launch Campaign"
            className="w-full bg-primary px-4 py-2 text-sm font-medium text-foreground"
            isDisabled={
              !runId ||
              !run ||
              !hasVideos ||
              isGeneratingVideos ||
              !generatedVideosState.some(v => v.isSelected && !v.isPreload)
            }
            isLoading={isLoading}
            onPress={launchCampaign}>
            <Trans>Launch Campaign</Trans>
          </TrackButton>
        </div>
      </StepContainer>
    </>
  );
});

ConfirmCampaign.displayName = 'ConfirmCampaign';

export default ConfirmCampaign;
