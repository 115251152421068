import {memo, useCallback, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {
  useMotionValue,
  useTransform,
  motion,
  animate,
  MotionValue,
  useTime,
} from 'framer-motion';
import debounce from 'lodash/debounce';
import {useEffect} from 'react';
import NoisePNG from './noise.png';
import {useResizeObserver} from '@/hooks';

interface Props {
  centerRef: React.RefObject<HTMLDivElement>;
  startRadius?: number;
  radiusIncrement?: number;
  isSuccess?: boolean;
  className?: string;
  radiusModifier?: (index: number, increment: number) => number;
}

const rippleSize = 10;

const AnimatedCircle = memo(
  ({
    radius,
    centerX,
    centerY,
    gradientId,
    currentStrokeColor,
    strokeOpacity,
    rippleProgress,
    index,
    totalCircles,
  }: {
    radius: number;
    centerX: MotionValue<number>;
    centerY: MotionValue<number>;
    gradientId: string;
    currentStrokeColor: MotionValue<string>;
    strokeOpacity: number;
    rippleProgress: MotionValue<number>;
    index: number;
    totalCircles: number;
  }) => {
    const baseRadiusValue = useMotionValue(radius);

    // Calculate the duration for one complete wave to travel through all circles
    const totalWaveDuration = Math.PI * 2;
    // Extend the animation window to ensure smooth completion
    const singleCircleDuration = totalWaveDuration * 0.25;

    // Calculate when this circle's animation should start and end
    // Distribute the start points evenly but give more room for completion
    const startPoint = (index / totalCircles) * (totalWaveDuration * 0.5);
    const peakPoint = startPoint + singleCircleDuration / 2;
    const endPoint = startPoint + singleCircleDuration;

    // Transform ripple progress into radius modification
    const rippleModifier = useTransform(rippleProgress, value => {
      // Normalize the progress value to our wave cycle
      const normalizedValue = value % totalWaveDuration;

      // If we're not within our animation window, return 0
      if (normalizedValue < startPoint || normalizedValue > endPoint) {
        return 0;
      }

      // Calculate progress within our animation window
      let progress;
      if (normalizedValue < peakPoint) {
        // Ramp up
        progress = (normalizedValue - startPoint) / (singleCircleDuration / 2);
      } else {
        // Ramp down
        progress =
          1 - (normalizedValue - peakPoint) / (singleCircleDuration / 2);
      }

      // Clamp progress between 0 and 1 and apply smooth easing
      progress = Math.max(0, Math.min(1, progress));
      // Apply sine easing for smoother animation
      progress = Math.sin((progress * Math.PI) / 2);

      return progress * rippleSize;
    });

    // Combine base radius with ripple modifier
    const finalRadius = useTransform(() => {
      return baseRadiusValue.get() + rippleModifier.get();
    });

    useEffect(() => {
      baseRadiusValue.set(radius);
    }, [radius]);

    return (
      <motion.circle
        key={radius}
        cx={centerX}
        cy={centerY}
        r={finalRadius}
        fill={`url(#${gradientId})`}
        style={{
          stroke: currentStrokeColor,
          opacity: 0.6,
        }}
        strokeOpacity={strokeOpacity}
      />
    );
  },
);

AnimatedCircle.displayName = 'AnimatedCircle';

const defaultModifier = (index: number, increment: number) => {
  return (
    Math.min(Math.pow(Math.max(1, index - 2), 1.5) * 0.4, 10) * increment +
    index * increment
  );
};

const ConcentricCircles = memo(
  ({
    centerRef,
    startRadius = 122,
    radiusIncrement = 60,
    isSuccess = false,
    className,
    radiusModifier = defaultModifier,
  }: Props) => {
    const [circles, setCircles] = useState<
      {
        radius: number;
        gradientId: string;
        opacity: number;
        strokeOpacity: number;
      }[]
    >([]);
    const measurementIntervalRef = useRef<any>(null);
    const numberOfCirclesRef = useRef(0);
    const maxPossibleRadiusRef = useRef(0);
    const successProgress = useMotionValue(0);
    const screenX = useMotionValue(window.innerWidth);
    const screenY = useMotionValue(document.documentElement.clientHeight);
    const centerY = useMotionValue(screenY.get() / 2 - 120); // default arbitrary value
    const centerX = useTransform(screenX, x => x / 2);

    // Add ripple animation using useTime
    const time = useTime();
    const rippleProgress = useTransform(time, t => {
      // Adjust speed of the ripple (lower divisor = faster ripple)
      return (t / 500) % (Math.PI * 2);
    });

    const currentGradientColor = useTransform(
      successProgress,
      [0, 1],
      ['rgb(96, 0, 231)', 'rgb(0, 231, 8)'],
    );

    const currentStrokeColor = useTransform(
      successProgress,
      [0, 1],
      ['rgb(150, 94, 255)', 'rgb(0, 231, 8)'],
    );

    // Transform maxPossibleRadius based on centerX and centerY
    const maxPossibleRadius = useTransform(() => {
      const x = centerX.get();
      const y = centerY.get();
      const windowWidth = screenX.get();
      const windowHeight = screenY.get();

      return Math.sqrt(
        Math.pow(Math.max(x, windowWidth - x), 2) +
          Math.pow(Math.max(y, windowHeight - y), 2),
      );
    });

    // Generate circles data based on maxPossibleRadius
    const circlesData = useTransform(() => {
      const radius = maxPossibleRadius.get();
      const circles = [];
      let circleCount = 0;
      let currentRadius = startRadius;

      while (currentRadius <= radius) {
        circles.push({
          radius: startRadius + radiusModifier(circleCount, radiusIncrement),
          gradientId: `circleGradient${circleCount}`,
          opacity: circleCount === 0 ? 0.35 : circleCount === 1 ? 0.3 : 0.2,
          strokeOpacity:
            circleCount === 0 ? 0.5 : circleCount === 1 ? 0.3 : 0.2,
        });
        circleCount++;
        const nextIncrement = radiusModifier(circleCount, radiusIncrement);
        currentRadius = startRadius + nextIncrement;
      }

      if (
        numberOfCirclesRef.current !== circleCount ||
        maxPossibleRadiusRef.current !== radius
      ) {
        numberOfCirclesRef.current = circleCount;
        maxPossibleRadiusRef.current = radius;
        setCircles(circles);
      }

      return circles;
    });

    const viewBox = useTransform(() => `0 0 ${screenX.get()} ${screenY.get()}`);

    const ellipseWidth = 1000;
    const ellipseHeight = useTransform(screenY, y => Math.min(y, 1000));
    const ellipseY = useTransform(() => {
      const y = screenY.get();
      const height = ellipseHeight.get();

      return y + height * 0.55;
    });

    useEffect(() => {
      animate(successProgress, isSuccess ? 1 : 0, {duration: 0.6});
    }, [isSuccess]);

    useEffect(() => {
      const handleResize = () => {
        screenX.set(window.innerWidth);
        screenY.set(document.documentElement.clientHeight);
      };

      const debouncedResize = debounce(handleResize, 100);

      window.addEventListener('resize', debouncedResize);
      window.addEventListener('orientationchange', debouncedResize);

      return () => {
        window.removeEventListener('resize', debouncedResize);
        window.removeEventListener('orientationchange', debouncedResize);
      };
    }, []);

    const onCenterMeasure = useCallback(() => {
      if (!centerRef.current) {
        return;
      }

      const rect = centerRef.current.getBoundingClientRect();

      centerY.set(rect.top + rect.height / 2);
    }, [centerRef]);

    useEffect(() => {
      // additional periodic measurement since ResizeObserver is not always called when offsetTop changes
      measurementIntervalRef.current = setInterval(() => {
        onCenterMeasure();
      }, 100);

      return () => {
        clearInterval(measurementIntervalRef.current);
      };
    }, [onCenterMeasure]);

    useResizeObserver(centerRef, onCenterMeasure);

    return (
      <motion.svg
        className={twMerge('h-full w-full', className)}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          {useTransform(circlesData, circles =>
            circles.map(({gradientId, opacity}) => (
              <radialGradient
                key={gradientId}
                id={gradientId}
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%">
                <stop
                  offset="0%"
                  style={{
                    stopColor: 'rgb(0, 0, 0)',
                    stopOpacity: opacity,
                  }}
                />
                <motion.stop
                  offset="100%"
                  style={{
                    stopOpacity: opacity,
                    stopColor: currentGradientColor,
                  }}
                />
              </radialGradient>
            )),
          ).get()}
          <pattern
            id="noiseOverlay"
            patternUnits="userSpaceOnUse"
            width="32"
            height="32">
            <image href={NoisePNG} width="48" height="48" />
          </pattern>
          <filter id="ellipseBlur" colorInterpolationFilters="sRGB">
            <feGaussianBlur in="SourceGraphic" stdDeviation="48" />
          </filter>
        </defs>

        {circles.map(({radius, gradientId, strokeOpacity}, index, array) => (
          <AnimatedCircle
            key={radius}
            centerX={centerX}
            centerY={centerY}
            radius={radius}
            gradientId={gradientId}
            currentStrokeColor={currentStrokeColor}
            strokeOpacity={strokeOpacity}
            rippleProgress={rippleProgress}
            index={index}
            totalCircles={array.length}
          />
        ))}

        <rect
          width="100%"
          height="100%"
          fill="url(#noiseOverlay)"
          style={{mixBlendMode: 'overlay', opacity: 0.6}}
        />

        <motion.ellipse
          cx={centerX}
          cy={ellipseY}
          rx={ellipseWidth / 2}
          ry={ellipseHeight}
          fill="black"
          filter="url(#ellipseBlur)"
        />
      </motion.svg>
    );
  },
);

ConcentricCircles.displayName = 'ConcentricCircles';

export default ConcentricCircles;
