'use client';

import {FC, PropsWithChildren} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppContext, useAuthContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {getIsOnboardingFlow} from '@/utils';

const ProtectedRoute: FC<
  PropsWithChildren<{checkVerified?: boolean; redirectTo?: string}>
> = ({checkVerified = true, redirectTo = '/create', children}) => {
  const isOnboarding = getIsOnboardingFlow();
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  if (!user) {
    return (
      <PortalSpinner
        classNames={{base: 'backdrop-blur-sm fixed inset-0 z-40'}}
        size="lg"
      />
    );
  }

  if (isOnboarding && !redirectTo.startsWith('/onboarding')) {
    return <Navigate to="/onboarding" replace />;
  }

  if (checkVerified && !user.verified) {
    return <Navigate to={redirectTo} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
