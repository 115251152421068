'use client';

import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import orderBy from 'lodash/orderBy';
import {FlowContext} from '@/contexts/FlowContext';
import {FlowRunErrorStep, FlowRunStatus} from '@/__generated__/graphql';
import {useMutation, useQuery} from '@apollo/client';
import {GET_FLOW_RUN} from '@/common/queries';
import dayjs from 'dayjs';
import {GET_FILE_BASE64, RUN_FLOW, UPLOAD_IMAGE} from '@/common/mutations';
import {
  GeneratedVideoState,
  FileUpload,
  PostPlatform,
  FlowSetting,
} from '@/common/types';
import {useNavigate, useParams} from 'react-router-dom';
import {base64ToFile, encodeErrorParam} from '@/utils';
import {VIDEOGEN_TIME} from '@/config';
import {FlowState, FlowAction, FlowStep} from './types';
import {
  clearStoredFlowState,
  getStoredFlowState,
  storeFlowState,
} from './utils';
import {useAppContext} from '@/hooks';

const initialState: FlowState = {
  currentStep: 'upload_photo',
  isInitialized: false,
  selectedImageIsLoading: false,
  selectedImage: undefined,
  selectedPlatform: undefined,
  promptText: '',
  promptCustomText: '',
  promptCategory: undefined,
  loaderProgress: undefined,
  sourceFileId: undefined,
  generatedVideosState: [],
  settings: [],
  errorMessage: undefined,
  errorStep: undefined,
  metaPresetToCreate: undefined,
};

function reducer(state: FlowState, action: FlowAction): FlowState {
  switch (action.type) {
    case 'SET_SELECTED_IMAGE':
      return {
        ...state,
        selectedImage: action.payload,
        sourceFileId: undefined,
      };
    case 'SET_SELECTED_IMAGE_IS_LOADING':
      return {...state, selectedImageIsLoading: action.payload};
    case 'SET_SELECTED_PLATFORM':
      return {...state, selectedPlatform: action.payload};
    case 'SET_PROMPT_TEXT':
      return {...state, promptText: action.payload};
    case 'SET_PROMPT_CUSTOM_TEXT':
      return {...state, promptCustomText: action.payload};
    case 'SET_PROMPT_CATEGORY':
      return {...state, promptCategory: action.payload};
    case 'SET_LOADER_PROGRESS':
      return {...state, loaderProgress: action.payload};
    case 'SET_SOURCE_FILE_ID':
      return {...state, sourceFileId: action.payload};
    case 'SET_GENERATED_VIDEOS_STATE':
      return {...state, generatedVideosState: action.payload};
    case 'UPDATE_GENERATED_VIDEO_STATE':
      return {
        ...state,
        generatedVideosState: state.generatedVideosState.map(video =>
          video.id === action.payload.id
            ? {...video, ...action.payload.data}
            : video,
        ),
      };
    case 'SET_CURRENT_STEP':
      return {...state, currentStep: action.payload};
    case 'UPDATE_SETTING_VALUE':
      return {
        ...state,
        settings: state.settings.map(setting =>
          setting.id === action.payload.id
            ? {...setting, value: action.payload.value as any}
            : setting,
        ),
      };
    case 'SET_META_PRESET_TO_CREATE':
      return {...state, metaPresetToCreate: action.payload};
    case 'SET_ERROR':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        errorStep: action.payload.errorStep,
      };
    case 'INITIALIZE_STATE':
      return {...state, ...action.payload, isInitialized: true};
    case 'RESET_STATE':
      return {...initialState, ...(action.payload ?? {}), isInitialized: false};
    default:
      return state;
  }
}

export const FlowProvider = memo(({children}: PropsWithChildren) => {
  const {runId} = useParams();
  const navigate = useNavigate();
  const {isAuthenticated} = useAppContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [
    uploadSourceMutation,
    {loading: uploadSourceLoading, error: uploadSourceError},
  ] = useMutation(UPLOAD_IMAGE, {
    context: {
      headers: {
        'apollo-require-preflight': 'true',
      },
    },
    onCompleted: ({file}) => {
      dispatch({type: 'SET_SOURCE_FILE_ID', payload: file.id});
    },
  });

  const [runFlowMutation, {loading: runFlowLoading, error: runFlowError}] =
    useMutation(RUN_FLOW, {
      update(cache, {data}) {
        if (!data) {
          return;
        }

        cache.writeQuery({
          query: GET_FLOW_RUN,
          variables: {id: data.runFlow.id},
          data: {getFlowRun: data.runFlow},
        });
      },
    });

  const [loadFileBase64] = useMutation(GET_FILE_BASE64, {ignoreResults: true});

  const runData = useQuery(GET_FLOW_RUN, {
    variables: {
      id: runId!,
    },
    fetchPolicy: 'network-only',
    skip: !runId,
  });

  const run = useMemo(() => {
    const data = runData.data?.getFlowRun;

    if (!data) {
      return undefined;
    }

    return {
      ...data,
      assets: data.assets.edges.map(({node}) => node),
      settings: data.settings.edges
        .map(({node}) => node)
        .sort((a, b) => a.sortValue - b.sortValue) as FlowSetting[],
    };
  }, [runData.data]);

  const isGeneratingVideos = Boolean(
    run && run.status === FlowRunStatus.GeneratingVideos && !run.errorStep,
  );

  const fieldsToStore = useMemo(
    () => ({
      selectedImage: state.selectedImage,
      promptCategory: state.promptCategory,
      promptText: state.promptText,
      promptCustomText: state.promptCustomText,
    }),
    [
      state.selectedImage,
      state.promptCategory,
      state.promptText,
      state.promptCustomText,
    ],
  );

  // On create store flow state
  useEffect(() => {
    if (runId || !state.isInitialized) {
      return;
    }

    storeFlowState(fieldsToStore);
  }, [fieldsToStore, runId, state.isInitialized]);

  // reset state on logout, do not include isInitialized in deps
  // to avoid reset in logged out state on initialization
  useEffect(() => {
    if (!isAuthenticated && state.isInitialized) {
      clearStoredFlowState();
      dispatch({type: 'RESET_STATE'});
    }
  }, [isAuthenticated]);

  // On create get initial stored state
  useEffect(() => {
    if (runId || state.isInitialized) {
      return;
    }

    getStoredFlowState().then(storedState => {
      dispatch({type: 'INITIALIZE_STATE', payload: storedState ?? {}});
    });
  }, [runId, state.isInitialized]);

  // initialize state with run data
  useEffect(() => {
    if (!run) {
      return;
    }

    const stateSettingsMap = Object.fromEntries(
      state.settings.map(setting => [setting.key, setting]),
    );
    const settings = run.settings
      .map(setting => ({
        ...setting,
        value: stateSettingsMap[setting.key]?.value ?? setting.value,
      }))
      .slice()
      .sort((a, b) => a.sortValue - b.sortValue);

    let payload: Partial<FlowState> = {
      settings,
      errorMessage: run.errorMessage ?? undefined,
      errorStep: run.errorStep ?? undefined,
    };

    if (!state.promptCategory) {
      payload = {
        ...payload,
        promptCategory: run.promptCategory ?? '',
        promptText:
          run.promptCategory === 'custom' ? '' : (run.promptText ?? ''),
        promptCustomText:
          run.promptCategory === 'custom' ? (run.promptText ?? '') : '',
      };
    }

    dispatch({
      type: 'INITIALIZE_STATE',
      payload,
    });
  }, [run]);

  // load selected image from run's source file
  useEffect(() => {
    (async () => {
      if (!run?.sourceFile?.id) {
        return;
      }

      try {
        dispatch({type: 'SET_SELECTED_IMAGE_IS_LOADING', payload: true});

        const result = await loadFileBase64({
          variables: {
            id: run.sourceFile.id,
          },
        });

        if (!result.data?.getFileBase64) {
          dispatch({type: 'SET_SELECTED_IMAGE_IS_LOADING', payload: false});
          return;
        }

        const file = base64ToFile(
          result.data.getFileBase64,
          run.sourceFile.displayName,
        );

        if (file) {
          dispatch({
            type: 'SET_SELECTED_IMAGE',
            payload: {
              url: URL.createObjectURL(file),
              file,
            },
          });

          dispatch({type: 'SET_SELECTED_IMAGE_IS_LOADING', payload: false});
        }
      } catch {
        dispatch({type: 'SET_SELECTED_IMAGE_IS_LOADING', payload: false});
      }
    })();
  }, [run]);

  const runStartDate = useMemo(() => {
    if (!run) {
      return undefined;
    }

    return run.videoGenerationStartedAt
      ? dayjs(run.videoGenerationStartedAt)
      : dayjs(run.createdAt);
  }, [run?.videoGenerationStartedAt]);

  useEffect(() => {
    if (!run?.assets) {
      return;
    }

    const assets = run.assets.map(file => {
      const prevFile = state.generatedVideosState.find(f => f.id === file.id);
      const isNewlyGenerated = runStartDate
        ? dayjs(file.createdAt).isAfter(runStartDate)
        : false;
      const isPreload = !file.videoFileId;
      const isSelected =
        run.status === FlowRunStatus.Initial || isPreload // deselect when coming back to the initial state or if it's a preload
          ? false
          : prevFile && !prevFile.isPreload
            ? prevFile.isSelected
            : isNewlyGenerated;

      return {
        id: file.id,
        name: file.videoFile?.name ?? '',
        thumbnailName:
          file.videoFile?.thumbnail?.name ??
          file.videoFile?.thumbnailName ??
          file.videoThumbnailFile?.name ??
          null,
        videoFileId: file.videoFileId,
        isSelected,
        isPreload,
        isNewlyGenerated,
        platform: file.platform,
        platformId: file.platformId,
        headline: file.headline,
        headlinePrompt: prevFile?.headlinePrompt ?? run.promptText ?? '',
        caption: file.caption,
        captionPrompt: prevFile?.captionPrompt ?? run.promptText ?? '',
        musicAuthor: file.musicAuthor,
        musicTitle: file.musicTitle,
        createdAt: file.createdAt,
        videoRegenerationsLeft: file.videoRegenerationsLeft,
        videoRegenerationStartedAt: file.videoRegenerationStartedAt,
        status: file.status,
        errorMessage: file.errorMessage,
        errorStep: file.errorStep,
      };
    });

    dispatch({
      type: 'SET_GENERATED_VIDEOS_STATE',
      payload: orderBy(
        assets,
        'createdAt',
        assets.some(asset => !asset.isNewlyGenerated) ? 'desc' : 'asc',
      ),
    });
  }, [run?.assets, run?.promptText, runStartDate]);

  useEffect(() => {
    const run = runData.data?.getFlowRun;

    if (!run) {
      return;
    }

    if (!isGeneratingVideos) {
      dispatch({type: 'SET_LOADER_PROGRESS', payload: undefined});
    }

    const date = run.videoGenerationStartedAt ?? run.createdAt;
    const startedAt = date
      ? dayjs(date.endsWith('Z') ? date : date + 'Z')
      : undefined;

    if (!startedAt) {
      return;
    }

    const interval = setInterval(() => {
      dispatch({
        type: 'SET_LOADER_PROGRESS',
        payload: Math.min(
          100,
          Math.max(0, (dayjs().diff(startedAt) / VIDEOGEN_TIME) * 100),
        ),
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isGeneratingVideos, runData.data?.getFlowRun]);

  useEffect(() => {
    if (!runData.data?.getFlowRun || !isGeneratingVideos) {
      return;
    }

    const interval = setInterval(() => {
      runData.refetch();
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [isGeneratingVideos, runData]);

  useEffect(() => {
    if (run && run.status !== FlowRunStatus.Initial && !run.errorStep) {
      setCurrentStep('configure_campaign');
    }
  }, [Boolean(run)]);

  useEffect(() => {
    if (runId && runData?.error && !runData.data) {
      navigate(`/create?error=${encodeErrorParam(runData.error.message)}`);
    }
  }, [runId, runData?.error, runData.data, navigate]);

  // Memoize dispatch functions
  const setSelectedImage = useCallback(
    (payload: FileUpload | undefined) =>
      dispatch({type: 'SET_SELECTED_IMAGE', payload}),
    [dispatch],
  );

  const setSelectedPlatform = useCallback(
    (payload: PostPlatform | undefined) =>
      dispatch({type: 'SET_SELECTED_PLATFORM', payload}),
    [dispatch],
  );

  const setPromptCategory = useCallback(
    (payload: string | undefined) =>
      dispatch({type: 'SET_PROMPT_CATEGORY', payload}),
    [dispatch],
  );

  const setPromptText = useCallback(
    (payload: string | undefined) =>
      dispatch({type: 'SET_PROMPT_TEXT', payload}),
    [dispatch],
  );

  const setPromptCustomText = useCallback(
    (payload: string | undefined) =>
      dispatch({type: 'SET_PROMPT_CUSTOM_TEXT', payload}),
    [dispatch],
  );

  const setGeneratedVideosState = useCallback(
    (payload: GeneratedVideoState[]) =>
      dispatch({type: 'SET_GENERATED_VIDEOS_STATE', payload}),
    [dispatch],
  );

  const setError = useCallback(
    (payload: {
      errorMessage: string | undefined;
      errorStep: FlowRunErrorStep | undefined;
    }) => dispatch({type: 'SET_ERROR', payload}),
    [dispatch],
  );

  const updateGeneratedVideoState = useCallback(
    (payload: {id: string; data: Partial<GeneratedVideoState>}) =>
      dispatch({type: 'UPDATE_GENERATED_VIDEO_STATE', payload}),
    [dispatch],
  );

  const setCurrentStep = useCallback(
    (payload: FlowStep) => dispatch({type: 'SET_CURRENT_STEP', payload}),
    [dispatch],
  );

  const updateSettingValue = useCallback(
    (id: string, value: FlowSetting['value']) =>
      dispatch({type: 'UPDATE_SETTING_VALUE', payload: {id, value}}),
    [dispatch],
  );

  const setMetaPresetToCreate = useCallback(
    (payload: FlowState['metaPresetToCreate']) =>
      dispatch({type: 'SET_META_PRESET_TO_CREATE', payload}),
    [dispatch],
  );

  const runFlow = useCallback(async () => {
    let fileId = state.sourceFileId;
    const prompt =
      state.promptCategory === 'custom'
        ? state.promptCustomText
        : state.promptText;

    if (
      !state.selectedImage ||
      !state.promptCategory ||
      !prompt ||
      runFlowLoading ||
      uploadSourceLoading ||
      state.selectedImageIsLoading
    ) {
      return;
    }

    if (!fileId) {
      const res = await uploadSourceMutation({
        variables: {
          input: {
            displayName: state.selectedImage.file.name,
            file: state.selectedImage.file,
          },
        },
      });

      if (res.data) {
        fileId = res.data.file.id;
      }
    }

    if (!fileId) {
      return;
    }

    const {data} = await runFlowMutation({
      variables: {
        input: {
          flowRunId: runId,
          promptCategory: state.promptCategory,
          promptText: prompt,
          sourceFileId: fileId,
        },
      },
    });

    if (data) {
      clearStoredFlowState();
      navigate(`/create/${data.runFlow.id}`);

      if (!data.runFlow.errorStep) {
        setCurrentStep('configure_campaign');
      }
    }
  }, [
    runId,
    state.sourceFileId,
    state.selectedImage,
    runFlowLoading,
    uploadSourceLoading,
    state.selectedImageIsLoading,
    state.promptCategory,
    state.promptText,
    state.promptCustomText,
    uploadSourceMutation,
    runFlowMutation,
    navigate,
    setCurrentStep,
  ]);

  const value = useMemo(
    () => ({
      run,
      runId,
      selectedImage: state.selectedImage,
      selectedPlatform: state.selectedPlatform,
      promptCategory: state.promptCategory,
      promptText: state.promptText,
      promptCustomText: state.promptCustomText,
      loaderProgress: state.loaderProgress,
      uploadSourceLoading,
      uploadSourceError,
      runFlowLoading,
      runFlowError,
      generatedVideosState: state.generatedVideosState,
      selectedImageIsLoading: state.selectedImageIsLoading,
      currentStep: state.currentStep,
      isGeneratingVideos,
      settings: state.settings,
      errorMessage: state.errorMessage,
      errorStep: state.errorStep,
      metaPresetToCreate: state.metaPresetToCreate,
      setSelectedImage,
      setSelectedPlatform,
      setPromptCategory,
      setPromptText,
      setPromptCustomText,
      runFlow,
      setGeneratedVideosState,
      updateGeneratedVideoState,
      setCurrentStep,
      setError,
      updateSettingValue,
      setMetaPresetToCreate,
    }),
    [
      run,
      runId,
      state,
      uploadSourceLoading,
      uploadSourceError,
      runFlowLoading,
      runFlowError,
      isGeneratingVideos,
      setSelectedImage,
      setSelectedPlatform,
      setPromptCategory,
      setPromptText,
      setPromptCustomText,
      runFlow,
      setGeneratedVideosState,
      updateGeneratedVideoState,
      setCurrentStep,
      setError,
      updateSettingValue,
      setMetaPresetToCreate,
    ],
  );

  return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
});

FlowProvider.displayName = 'FlowProvider';
