'use client';

import ModalOutlet from '@/components/ModalOutlet';
import {memo} from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import GenerateCampaign from './GenerateCampaign';
import NewCampaignHeader from './components/NewCampaignHeader';
import {useQuery} from '@apollo/client';
import {GET_FLOW_RUN_CONNECTION} from '@/common/queries';
import {useAppContext, useAuthContext} from '@/hooks';
import InAppDialog from './InAppDialog';
import {FlowRunStatus} from '@/__generated__/graphql';
import PortalSpinner from '@/components/PortalSpinner';

const subPath = ['auth', 'asset', 'fine-tune', 'meta-presets'];

const NewCampaign = memo(() => {
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const {pathname} = useLocation();
  const {runId} = useParams();
  const basePath = runId ? `/create/${runId}` : 'create';
  const flowRuns = useQuery(GET_FLOW_RUN_CONNECTION, {
    variables: {
      filter: {
        userId: user?.id,
        campaignId: {$ne: null},
        errorMessage: {$eq: null},
      },
      first: 1,
      order: {createdAt: 'asc'},
    },
    skip: !user,
    fetchPolicy: 'cache-and-network',
  });
  const hasSuccessfulFlowRun = Boolean(
    flowRuns.data?.getFlowRunConnection?.edges.length,
  );
  const unfinishedRunResult = useQuery(GET_FLOW_RUN_CONNECTION, {
    variables: {
      filter: {
        status: FlowRunStatus.Finished,
        campaign_created_at: {$is_null: true},
      },
      order: {createdAt: 'desc'},
      first: 1,
    },
    fetchPolicy: 'network-only',
    skip: Boolean(runId || !isAuthenticated),
  });
  const unfinishedRun =
    unfinishedRunResult.data?.getFlowRunConnection.edges[0]?.node;

  if (unfinishedRun) {
    return <Navigate to={`/create/${unfinishedRun.id}`} replace />;
  }

  return (
    <>
      <InAppDialog />
      <ModalOutlet
        isDismissable={pathname.includes('/asset')}
        basePath={basePath}
        subPath={subPath}
      />
      <div className="flex w-full max-w-[min(100dvw,42rem)] grow flex-col gap-5 p-4">
        <NewCampaignHeader canClose={hasSuccessfulFlowRun} />
        {unfinishedRunResult.loading ? (
          <PortalSpinner
            classNames={{base: 'absolute backdrop-blur-sm inset-0 z-40'}}
            size="lg"
          />
        ) : (
          <GenerateCampaign
            showBanner={
              !hasSuccessfulFlowRun &&
              Boolean(flowRuns.data?.getFlowRunConnection || !isAuthenticated)
            }
          />
        )}
      </div>
    </>
  );
});

NewCampaign.displayName = 'NewCampaign';

export default NewCampaign;
