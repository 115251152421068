'use client';

import dayjs from '@/common/dayjs';
import {LRUCache} from 'lru-cache';
import axios from 'axios';
import {
  LS_AUTH_TOKEN_KEY,
  LS_INITIAL_QS_AFTER_LOGIN,
  LS_IS_ONBOARDING_FLOW,
  LS_REFRESH_TOKEN_KEY,
} from './config';
import {AppProviderState} from './contexts';
import {ApolloClient} from '@apollo/client';
import {User, TokenResponse, ErrorURLParam} from '@/common/types';
import {URLRegex} from './yupSetup';

const dateCache = new LRUCache<
  string | number | Date | dayjs.Dayjs,
  dayjs.Dayjs
>({
  max: 300,
});

export function getDayjsDate(
  date?: string | number | Date | dayjs.Dayjs | null,
  withTZOffset = true,
): dayjs.Dayjs | undefined {
  if (!date) {
    return;
  }

  const cacheKey = `${(() => {
    if (typeof date === 'string') {
      return date;
    }

    if (date instanceof Date || date instanceof dayjs.Dayjs) {
      return date.toISOString();
    }

    return date;
  })()}_${withTZOffset}`;

  if (dateCache.has(cacheKey)) {
    return dateCache.get(cacheKey);
  }

  const dayjsTZDate = dayjs(date);

  if (!dayjsTZDate.isValid()) {
    return;
  }

  const dayjsDate = withTZOffset
    ? dayjsTZDate.add(dayjsTZDate.utcOffset(), 'minute')
    : dayjsTZDate;

  dateCache.set(cacheKey, dayjsDate);

  return dayjsDate;
}

export function JSONSafeParse<T = any>(
  text: string,
  reviver?: (this: any, key: string, value: any) => any,
): T | undefined {
  try {
    return JSON.parse(text, reviver);
  } catch (error) {
    return;
  }
}

let refreshRequest: Promise<string> | undefined;

const refreshAccessToken = async (
  refreshToken: string,
  login: AppProviderState['login'],
  logout: AppProviderState['logout'],
  client: ApolloClient<any> | undefined,
  setUserId: (userId?: string) => Promise<void>,
): Promise<string> => {
  try {
    const form = new FormData();

    form.append('grant_type', 'refresh_token');
    form.append('refresh_token', refreshToken ?? '');

    const resp = await axios.post<TokenResponse>('token', form);
    const accessToken = resp.data?.access_token;

    if (!accessToken) {
      throw new Error('Unauthorized');
    }

    await setUserId(resp.data.user?.id);

    login(resp.data);

    return accessToken;
  } catch (error) {
    logout(client);

    throw new Error('Unauthorized');
  } finally {
    refreshRequest = undefined;
  }
};

export const getAccessToken = () => {
  return localStorage.getItem(LS_AUTH_TOKEN_KEY);
};

export async function fetchNewToken(
  login: AppProviderState['login'],
  logout: AppProviderState['logout'],
  client: ApolloClient<any> | undefined,
  setUserId: (userId?: string) => Promise<void>,
): Promise<string> {
  const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
  const authToken = localStorage.getItem(LS_AUTH_TOKEN_KEY);

  if (!authToken || !refreshToken) {
    logout(client);

    throw new Error('Unauthorized');
  }

  if (refreshRequest) {
    return refreshRequest;
  }

  refreshRequest = refreshAccessToken(
    refreshToken,
    login,
    logout,
    client,
    setUserId,
  );

  return await refreshRequest;
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const isUrl = (url?: string) => {
  if (!url) {
    return true;
  }

  return URLRegex.test(url);
};

export async function blobToBase64(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
  });
}

export const getFileFromCache = async (key: string) => {
  const rawCache = localStorage.getItem(key);

  if (!rawCache) {
    return undefined;
  }

  const cache = JSONSafeParse<{
    base64: string;
    filename: string;
    type: string;
  }>(rawCache);

  if (!cache) {
    return undefined;
  }

  const {base64, filename, type} = cache;
  const response = await fetch(base64);
  const blob = await response.blob();

  return {
    url: URL.createObjectURL(blob),
    file: new File([blob], filename, {type}),
  };
};

export const cacheFile = async (key: string, file: File) => {
  const cache = {
    base64: await blobToBase64(file),
    filename: file.name,
    type: file.type,
  };
  localStorage.setItem(key, JSON.stringify(cache));
};

export function toBase64(str: string) {
  const utf8Bytes = new TextEncoder().encode(str);
  const binaryString = String.fromCharCode(...utf8Bytes);

  return btoa(binaryString);
}

export function fromBase64(base64: string) {
  const binaryString = atob(base64);
  const utf8Bytes = Uint8Array.from(binaryString, char => char.charCodeAt(0));

  return new TextDecoder().decode(utf8Bytes);
}
interface AuthParam {
  access_token: string;
  refresh_token?: string;
  provider: string;
  signup: boolean;
  email: string;
  id: string;
}

export function decodeAuthParam(authParam: string): AuthParam | null {
  try {
    const decodedString = fromBase64(authParam);

    return JSON.parse(decodedString) ?? null;
  } catch (error) {
    console.error('Failed to decode auth parameter:', error);
    return null;
  }
}

export function getParamsAfterLogin(): URLSearchParams | undefined {
  try {
    const paramsString = sessionStorage.getItem(LS_INITIAL_QS_AFTER_LOGIN);

    if (!paramsString) {
      return;
    }

    return new URLSearchParams(paramsString);
  } catch {
    return;
  }
}

export function setParamsAfterLogin(params: URLSearchParams) {
  const paramsString = params.toString();

  try {
    sessionStorage.setItem(LS_INITIAL_QS_AFTER_LOGIN, paramsString);
  } catch {
    // pass
  }
}

export function deleteParamsAfterLogin() {
  try {
    sessionStorage.removeItem(LS_INITIAL_QS_AFTER_LOGIN);
  } catch {
    // pass
  }
}

export const getUserName = (
  user?: {
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
  },
  defaultName: string = '',
) => {
  return (
    `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim() || defaultName
  );
};

export const getUserInitials = (user?: User, defaultName: string = '') => {
  if (!user) {
    return defaultName;
  }

  return (
    [user.firstName?.[0], user.lastName?.[0]].filter(Boolean).join('') ??
    defaultName
  );
};

export function encodeErrorParam(error: string): string {
  return toBase64(JSON.stringify(encodeURIComponent(error)));
}

export function decodeErrorParam(errorParam: string): ErrorURLParam | null {
  try {
    const decodedString = fromBase64(
      errorParam.replace(/-/g, '+').replace(/_/g, '/'),
    );

    return JSON.parse(decodedString) ?? null;
  } catch (error) {
    console.error('Failed to decode error parameter:', error);
    return null;
  }
}

export const arrayOfAll =
  <T>() =>
  <U extends T[]>(
    array: U & ([T] extends [U[number]] ? unknown : 'Invalid') & {0: T},
  ) =>
    array;

export const getWindow = () => {
  if (typeof window !== 'undefined') {
    return window;
  }
};

export const base64ToFile = (base64: string, filename: string) => {
  const [header, code] = base64.split(',');
  const contentType = header.replace(/(data:)|(;base64)/g, '');

  const byteStr = atob(code);
  const bytes = new Array(byteStr.length);
  for (let i = 0; i < byteStr.length; i++) {
    bytes[i] = byteStr.charCodeAt(i);
  }
  const byteArray = new Uint8Array(bytes);
  const blob = new Blob([byteArray], {type: contentType});

  return new File([blob], filename, {type: blob.type});
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

export function setIsOnboardingFlow(value: boolean) {
  try {
    if (value) {
      localStorage.setItem(LS_IS_ONBOARDING_FLOW, 'true');
    } else {
      localStorage.removeItem(LS_IS_ONBOARDING_FLOW);
    }
  } catch {
    // pass
  }
}

export function getIsOnboardingFlow() {
  try {
    return localStorage.getItem(LS_IS_ONBOARDING_FLOW) === 'true';
  } catch {
    return false;
  }
}
