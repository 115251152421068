export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';
// use this from time to time for cross-browser testing local frontend remotely
// export const API_URL = 'https://dev-api.portal.ai';
export const API_LIMIT = 20;
export const MODE = import.meta.env.MODE || 'development';
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT || 'local';
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY || '';
export const AMPLITUDE_OPT_OUT = ENVIRONMENT === 'local';
export const SENTRY_OPT_OUT = ENVIRONMENT === 'local';
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';
export const SENTRY_TRACES_SAMPLE_RATE =
  import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1;
export const SENTRY_PROFILES_SAMPLE_RATE =
  import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE || 1;
export const LEGACY_AUTH_ENABLED =
  import.meta.env.VITE_LEGACY_AUTH_ENABLED === 'true';
export const AWS_TRANSCRIBE_IDENTITY_POOL_ID =
  import.meta.env.VITE_AWS_TRANSCRIBE_IDENTITY_POOL_ID || '';
export const AWS_REGION =
  AWS_TRANSCRIBE_IDENTITY_POOL_ID.split(':')[0] || 'us-west-2';

export const LS_AUTH_TOKEN_KEY = 'authToken';
export const LS_REFRESH_TOKEN_KEY = 'refreshToken';
export const LS_INITIAL_QS_AFTER_LOGIN = 'initialQsAfterLogin';
export const QS_USER_COUPON_TYPE = 'userCouponType';
export const LS_USER_COUPON_TYPE = QS_USER_COUPON_TYPE;
export const LS_PHOTO_UPLOAD_PREFIX = 'sourcePhotoUpload';
export const LS_FLOW_STATE_CACHE = 'flowStateCache';
export const LS_IS_ONBOARDING_FLOW = 'isOnboardingFlow';

export const VIDEOGEN_TIME = 1000 * 60 * 5;
export const REGENERATE_VIDEO_TIME = 1000 * 60 * 5;
export const VIDEOGEN_VIDEO_COUNT = 5;
export const VIDEOGEN_PRELOAD_TO_ASSET_TIME = 1000 * 60 * 3;
