'use client';

import {SVGProps} from 'react';

export default function PenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.5 22.1667L8.04927 20.0324C8.40421 19.8958 8.58168 19.8276 8.74772 19.7385C8.8952 19.6593 9.0358 19.5679 9.16804 19.4653C9.31692 19.3498 9.45137 19.2153 9.72028 18.9464L21 7.66669C22.1046 6.56212 22.1046 4.77125 21 3.66668C19.8955 2.56212 18.1046 2.56211 17 3.66668L5.72028 14.9464C5.45138 15.2153 5.31692 15.3498 5.20139 15.4986C5.09877 15.6309 5.0074 15.7715 4.92823 15.919C4.83911 16.085 4.77085 16.2625 4.63433 16.6174L2.5 22.1667ZM2.5 22.1667L4.55812 16.8156C4.7054 16.4327 4.77903 16.2413 4.90534 16.1536C5.01572 16.0769 5.1523 16.0479 5.2843 16.0731C5.43533 16.102 5.58038 16.247 5.87048 16.5371L8.12957 18.7962C8.41967 19.0863 8.56472 19.2314 8.59356 19.3824C8.61877 19.5144 8.58979 19.651 8.51314 19.7614C8.42545 19.8877 8.23399 19.9613 7.85107 20.1086L2.5 22.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
