import {memo, useMemo} from 'react';
import {AreaChartMetric as AreaChartMetricType} from '../types';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import AreaChart from '@/components/charts/AreaChart';
import {MetricTrend} from './components/MetricTrend';

export const AreaChartMetric = memo(
  ({metric, className}: {metric: AreaChartMetricType; className?: string}) => {
    const {i18n, _} = useLingui();
    const unit = useMemo(() => {
      switch (metric.valueUnit) {
        case 'pcs':
          return _(msg`pcs.`);
        default:
          return null;
      }
    }, [metric.valueUnit, _]);

    return (
      <div
        className={twMerge(
          'col-span-2 grid grid-cols-2 justify-between gap-10 text-sm',
          className,
        )}>
        <div className="flex flex-col justify-between gap-3">
          <h1 className="text-sm text-foreground/50">{metric.title}</h1>
          <div className="flex flex-col gap-1">
            <div className="flex items-end gap-1">
              <span className="text-xl">
                {i18n.number(metric.value, metric.valueFormat)}
              </span>
              {unit && <span className="text-foreground/50">{unit}</span>}
            </div>
            <MetricTrend value={metric.trend} />
          </div>
        </div>
        <AreaChart data={metric.chartData} yDataKeys={metric.chartYDataKeys} />
      </div>
    );
  },
);

AreaChartMetric.displayName = 'AreaChartMetric';
