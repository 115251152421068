'use client';

import PortalLogo from '@/components/icons/PortalLogo';
import SideLogoSVG from './onboarding-side-logo.svg';
import {memo} from 'react';

const OnboardingHeader = memo(() => {
  return (
    <div className="sticky flex h-[3.25rem] w-full max-w-[min(100dvw,42rem)] shrink-0 items-center justify-between gap-4">
      <PortalLogo className="flex shrink-0 text-foreground" />
      <img src={SideLogoSVG} alt="Portal AI" className="h-auto w-auto" />
    </div>
  );
});

OnboardingHeader.displayName = 'OnboardingHeader';

export default OnboardingHeader;
