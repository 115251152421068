import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useLayoutEffect,
  useState,
} from 'react';

interface Props {
  firstName?: string;
  backgroundImage: string | ArrayBuffer | null;
  isRecord: boolean;
  showDate: boolean;
  minutes?: string;
  seconds?: string;
  milliseconds?: string;
  setIsSvgReady: Dispatch<SetStateAction<boolean>>;
}

const REF_SIZE = 393;
const FINAL_SIZE = 1920;
const RATIO = FINAL_SIZE / REF_SIZE;
const FONT_FAMILY = 'Inter, sans-serif';

// Core dimensions
const CONTENT_HEIGHT = 248 * RATIO;

// Font sizes
const EMOJI_F_SIZE = 72 * RATIO;
const TITLE_F_SIZE = 20 * RATIO;
const SUBTITLE_F_SIZE = 16 * RATIO;
const TIME_PART_F_SIZE = 30 * RATIO;
const TIME_TEXT_F_SIZE = 14 * RATIO;

// Offsets
const TITLE_OFFSET = 24 * RATIO;
const SUBTITLE_OFFSET = 8 * RATIO;
const TIME_OFFSET = 16 * RATIO;
const TIME_GAP = 4 * RATIO;

const CampaignSVG = forwardRef(
  (
    {
      firstName,
      backgroundImage,
      isRecord,
      showDate,
      minutes,
      seconds,
      milliseconds,
      setIsSvgReady,
    }: Props,
    ref: React.Ref<SVGSVGElement>,
  ) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const CENTER_X = FINAL_SIZE / 2;
    const CENTER_Y = FINAL_SIZE / 2;

    // Calculate vertical positions relative to content center
    const contentStartY = CENTER_Y - CONTENT_HEIGHT / 2;

    // Emoji position at the top of content
    const EMOJI_Y = contentStartY + EMOJI_F_SIZE / 2;

    // Title position after emoji section
    const TITLE_Y = contentStartY + EMOJI_F_SIZE + TITLE_OFFSET;

    // Subtitle positions title
    const SUBTITLE_1_Y = TITLE_Y + SUBTITLE_OFFSET + TITLE_F_SIZE;
    const SUBTITLE_2_Y = SUBTITLE_1_Y + SUBTITLE_F_SIZE + 4 * RATIO;

    const TIMER_Y = SUBTITLE_2_Y + SUBTITLE_F_SIZE + TIME_OFFSET;

    useLayoutEffect(() => {
      if (isImageLoaded) {
        setIsSvgReady(true);
      }
    }, [isImageLoaded]);

    return (
      <svg
        ref={ref}
        aria-hidden="true"
        width={FINAL_SIZE}
        height={FINAL_SIZE}
        className="pointer-events-none invisible fixed left-0 top-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg">
        {/* This defs is for font loading, do not edit or remove it */}
        {/* look into svg serialization in useCanvasToShare */}
        <defs />

        {/* Background Image */}
        {backgroundImage ? (
          <image
            href={backgroundImage as string}
            // eslint-disable-next-line react/no-unknown-property
            onLoad={() => setIsImageLoaded(true)}
            x="0"
            y="0"
            width={FINAL_SIZE}
            height={FINAL_SIZE}
          />
        ) : null}

        {/* Emoji */}
        <text
          x={CENTER_X}
          y={EMOJI_Y}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={EMOJI_F_SIZE}>
          {isRecord ? '🦄' : '🚀'}
        </text>

        {/* Check Circle Icon */}
        <g
          transform={`translate(${CENTER_X + EMOJI_F_SIZE / RATIO + 8 * RATIO}, ${EMOJI_Y + EMOJI_F_SIZE / RATIO - 8 * RATIO}) scale(${RATIO})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM17.2071 9.70711C17.5976 9.31658 17.5976 8.68342 17.2071 8.29289C16.8166 7.90237 16.1834 7.90237 15.7929 8.29289L10.5 13.5858L8.20711 11.2929C7.81658 10.9024 7.18342 10.9024 6.79289 11.2929C6.40237 11.6834 6.40237 12.3166 6.79289 12.7071L9.79289 15.7071C10.1834 16.0976 10.8166 16.0976 11.2071 15.7071L17.2071 9.70711Z"
            fill="url(#paint0_linear_4610_29944)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4610_29944"
              x1="12"
              y1="1"
              x2="12"
              y2="23"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#B3FF00" />
              <stop offset="1" stopColor="#00FF7B" />
            </linearGradient>
          </defs>
        </g>

        {/* Main Title */}
        <text
          x={CENTER_X}
          y={TITLE_Y}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#ffffff"
          fontSize={TITLE_F_SIZE}
          fontFamily={FONT_FAMILY}
          fontWeight={600}>
          {isRecord
            ? 'New Personal Best!'
            : firstName
              ? `Congrats, ${firstName}!`
              : 'Congrats!'}
        </text>

        {/* First Subtitle */}
        <text
          x={CENTER_X}
          y={SUBTITLE_1_Y}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="rgba(255, 255, 255, 0.5)"
          fontSize={SUBTITLE_F_SIZE}
          fontFamily={FONT_FAMILY}>
          {`You’ve Successfully`}
        </text>

        {/* Second Subtitle */}
        <text
          x={CENTER_X}
          y={SUBTITLE_2_Y}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="rgba(255, 255, 255, 0.5)"
          fontSize={SUBTITLE_F_SIZE}
          fontFamily={FONT_FAMILY}>
          {showDate
            ? 'Launched the Campaign in just:'
            : 'Launched the Campaign'}
        </text>

        {/* Time Parts */}
        {showDate && (
          <g>
            {(() => {
              const timePartWidth = 80 * RATIO + TIME_GAP;
              const timePartHeight = 52 * RATIO;
              const gap = 20 * RATIO;

              const timeParts = [
                {key: 'minutes', value: minutes, label: 'min'},
                {key: 'seconds', value: seconds, label: 's'},
                {key: 'milliseconds', value: milliseconds, label: 'ms'},
              ].filter(part => part.value !== undefined);
              const offsets = [0 * RATIO, 0 * RATIO, 0 * RATIO];

              const totalWidth =
                timePartWidth * timeParts.length + gap * (timeParts.length - 1);
              const startX = CENTER_X - totalWidth / 2;

              return timeParts.map((part, index) => (
                <g key={part.key}>
                  <rect
                    x={startX + index * (timePartWidth + gap)}
                    y={TIMER_Y}
                    width={timePartWidth}
                    height={timePartHeight}
                    fill="#262626"
                    rx={10 * RATIO}
                  />
                  {/* Time value and label in a horizontal layout */}
                  <g
                    transform={`translate(${startX + index * (timePartWidth + gap)}, ${TIMER_Y})`}>
                    <text
                      x={timePartWidth / 2 + offsets[index]}
                      y={timePartHeight / 2 + TIME_PART_F_SIZE / 4 + 2 * RATIO}
                      textAnchor="middle"
                      fill="#ffffff"
                      fontSize={TIME_PART_F_SIZE}
                      fontFamily={FONT_FAMILY}>
                      <tspan fontWeight={600} baselineShift={0}>
                        {part.value}
                      </tspan>
                      <tspan
                        fill="rgba(255, 255, 255, 0.5)"
                        dx={TIME_GAP}
                        fontSize={TIME_TEXT_F_SIZE}
                        baselineShift={0}>
                        {part.label}
                      </tspan>
                    </text>
                  </g>
                  {/* Add separator ":" after each time part except the last one */}
                  {index < timeParts.length - 1 && (
                    <text
                      x={
                        startX +
                        (index + 1) * timePartWidth +
                        index * gap +
                        gap / 2
                      }
                      y={TIMER_Y + timePartHeight / 2}
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fill="rgba(255, 255, 255, 0.5)"
                      fontSize={TIME_PART_F_SIZE}
                      fontFamily={FONT_FAMILY}>
                      :
                    </text>
                  )}
                </g>
              ));
            })()}
          </g>
        )}
      </svg>
    );
  },
);

CampaignSVG.displayName = 'CampaignSVG';

export default CampaignSVG;
