import {useAuthContext} from '@/hooks';
import {Avatar} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {memo} from 'react';
import PortalLogo from '@/components/icons/PortalLogo';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

export const LiveHeader = memo(() => {
  const {user} = useAuthContext();
  const {_} = useLingui();

  return (
    <div className="flex w-full items-center justify-between gap-3 py-3">
      <PortalLogo className="flex shrink-0 text-foreground" />
      <div className="relative flex shrink-0">
        <Avatar
          aria-label={_(msg`Profile`)}
          data-amp-track-label={`Profile`}
          as={RouterLink}
          to={'/profile'}
          src={user?.avatar ?? undefined}
          className="h-7 w-7 shrink-0"
        />
        <div className="pointer-events-none absolute inset-0 rounded-full border-foreground/15 [border-width:1.5px]" />
      </div>
    </div>
  );
});

LiveHeader.displayName = 'LiveHeader';
