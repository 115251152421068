import {
  AdStatusCategory,
  FlowPlatform,
  GetFlowRunAssetConnectionQuery,
} from '@/__generated__/graphql';
import usePresignedLink from '@/hooks/usePresignedLink';
import {Link as RouterLink} from 'react-router-dom';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {memo, useMemo} from 'react';
import MetaSvg from '@/assets/socials/auth/meta.svg';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import {twMerge} from 'tailwind-merge';
import dayjs from 'dayjs';
import {useCountdown} from '@/hooks/useCountdown';
import {useQuery} from '@apollo/client';
import {GET_FLOW_RUN_ASSET_AD_INFO} from '@/common/queries';

const PERFORMANCE_DELAY_HOURS = 72;

export const Creative = memo(
  ({
    id,
    videoFile,
    headline,
    platform,
    grossProfitMetric,
    cacMetric,
    impressionsMetric,
    flowRun: {campaignCreatedAt},
  }: GetFlowRunAssetConnectionQuery['connection']['edges'][0]['node']) => {
    const adInfoQuery = useQuery(GET_FLOW_RUN_ASSET_AD_INFO, {
      variables: {id},
      fetchPolicy: 'cache-first',
    });
    const adInfo = adInfoQuery.data?.getFlowRunAssetAdInfo;
    const endTime = useMemo(
      () => dayjs.utc(campaignCreatedAt).add(PERFORMANCE_DELAY_HOURS, 'hours'),
      [campaignCreatedAt],
    );
    const countdown = useCountdown(endTime, 500);
    const {_, i18n} = useLingui();
    const thumbnailURL = usePresignedLink(
      videoFile?.thumbnail?.name ?? videoFile?.thumbnailName,
    );
    const platformLabel = useMemo(() => {
      switch (platform) {
        case FlowPlatform.Meta:
          return _(msg`Meta Ads`);
        case FlowPlatform.Tiktok:
          return _(msg`TikTok`);
        default:
          return;
      }
    }, [platform, _]);
    const platformImgSrc = useMemo(() => {
      switch (platform) {
        case FlowPlatform.Meta:
          return MetaSvg;
        default:
          return;
      }
    }, [platform]);
    const metric1 = useMemo(() => {
      if (grossProfitMetric) {
        return {
          title: _(msg`Gross Profit`),
          value: i18n.number(grossProfitMetric?.value, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits:
              (grossProfitMetric?.value || 0) < 100 ? 2 : 0,
          }),
        };
      }

      return {
        title: _(msg`Impressions`),
        value: i18n.number(impressionsMetric?.value ?? 0),
      };
    }, [grossProfitMetric, impressionsMetric, _, i18n]);
    const metric2 = useMemo(() => {
      if (countdown.asMilliseconds() > 0) {
        return;
      }

      return {
        title: _(msg`Customer Acq. Cost`),
        value: i18n.number(cacMetric?.value, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: (cacMetric?.value || 0) < 100 ? 2 : 0,
        }),
      };
    }, [countdown, cacMetric, _, i18n]);

    const statusBgColor = (() => {
      switch (adInfo?.statusCategory) {
        case AdStatusCategory.Positive:
          return 'bg-success';
        case AdStatusCategory.Neutral:
          return 'bg-default-500';
        case AdStatusCategory.Warning:
          return 'bg-[#FF8000]';
        case AdStatusCategory.Negative:
          return 'bg-[#F00]';
      }
    })();

    return (
      <Button
        as={RouterLink}
        to={`/ad/${id}`}
        disableRipple
        aria-label={_(msg`Creative`)}
        data-amp-track-label="Creative"
        className="h-auto w-full flex-col gap-5 rounded-[1.25rem] bg-[#262626] p-4 text-start">
        <div className="flex w-full gap-4">
          {thumbnailURL ? (
            <img
              src={thumbnailURL}
              alt="thumbnail"
              className="h-11 w-11 shrink-0 rounded-lg object-cover"
            />
          ) : (
            <div className="h-11 w-11 rounded-lg" />
          )}
          <div className="flex w-full flex-col gap-1">
            <div className="flex w-full">
              <div className="flex-1 text-sm font-medium">{headline}</div>
              <ChevronLeftIcon className="h-5 w-5 shrink-0 rotate-180 text-foreground/25" />
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                <img
                  src={platformImgSrc}
                  alt={platformLabel ?? 'platform icon'}
                  className="h-4 w-4 shrink-0 object-contain object-center"
                />
                <div>{platformLabel}</div>
              </div>
              {adInfo ? (
                <div className="flex items-center gap-2">
                  <span
                    className={twMerge(
                      'h-[0.375rem] w-[0.375rem] shrink-0 rounded-full',
                      statusBgColor,
                    )}
                  />
                  {adInfo?.status}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full border-t-[length:thin] border-foreground/[0.08]"></div>
        <div className="flex w-full gap-2">
          <div className="flex flex-1 flex-col gap-1">
            <div className="text-sm font-medium">{metric1.value}</div>
            <div className="text-xs text-foreground/50">{metric1.title}</div>
          </div>
          {metric2 ? (
            <div className="flex flex-1 flex-col gap-1">
              <div className="text-sm font-medium">{metric2.value}</div>
              <div className="text-xs text-foreground/50">{metric2.title}</div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col gap-1">
              <div className="text-xs text-foreground/50">
                <Trans>Performance ready in:</Trans>
              </div>
              <div className="text-sm font-medium tabular-nums">
                <Trans>
                  ⏳ {countdown.days() * 24 + countdown.hours()} h :{' '}
                  {String(countdown.minutes()).padStart(2, '0')} min :{' '}
                  {String(countdown.seconds()).padStart(2, '0')} s
                </Trans>
              </div>
            </div>
          )}
        </div>
      </Button>
    );
  },
);

Creative.displayName = 'Creative';
