import {ReactNode, useRef, useEffect, forwardRef} from 'react';
import {motion, useMotionValue, useTransform, animate} from 'framer-motion';
import {twMerge} from 'tailwind-merge';
import {FlowStep} from '@/components/providers/FlowProvider/types';
import {useFlowContext} from '@/hooks';
import _ from 'lodash';

interface Props {
  step: FlowStep;
  title: string | ReactNode;
  titleCompleted?: string | ReactNode;
  subtitle: string | ReactNode;
  icon: ReactNode;
  iconCompleted?: ReactNode;
  isCompleted: boolean;
  children: ReactNode;
  containerClassName?: string;
  className?: string;
  renderTitleContent?: () => ReactNode;
}

const StepContainer = forwardRef<HTMLDivElement, Props>(
  (
    {
      step,
      title,
      titleCompleted,
      icon,
      iconCompleted,
      subtitle,
      children,
      containerClassName,
      className,
      isCompleted,
      renderTitleContent,
    },
    ref,
  ) => {
    const {currentStep} = useFlowContext();
    const isOpen = currentStep === step;
    const contentRef = useRef<HTMLDivElement>(null);
    const height = useMotionValue(0);
    const opacity = useTransform(height, [0, 10], [0, 1]);
    const marginTop = useTransform(height, [0, 10], [0, 32]);

    useEffect(() => {
      if (!contentRef.current) return;

      // Create resize observer to update height when content changes
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const targetHeight = isOpen ? entry.contentRect.height : 0;
          animate(height, targetHeight, {
            duration: 0.2,
            ease: 'easeInOut',
          });
        }
      });

      resizeObserver.observe(contentRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }, [isOpen, height]);

    useEffect(() => {
      if (!contentRef.current) {
        return;
      }

      const targetHeight = isOpen ? contentRef.current.offsetHeight : 0;

      animate(height, targetHeight, {
        duration: 0.2,
        ease: 'easeInOut',
      });
    }, [isOpen, height]);

    return (
      <div
        ref={ref}
        className={twMerge(
          'flex w-full flex-col rounded-2xl bg-foreground/15 py-4 shadow-lg',
          containerClassName,
        )}>
        <div className="flex w-full flex-col gap-4 px-4">
          <div className="flex w-full items-start gap-3">
            {isCompleted && iconCompleted ? iconCompleted : icon}
            <div className="flex grow items-center self-stretch">
              <div className="flex grow flex-col">
                <div className={`font-semibold ${isOpen ? 'text-xl' : ''}`}>
                  {isCompleted && titleCompleted ? titleCompleted : title}
                </div>
                {isCompleted ? null : (
                  <div className="text-xs text-foreground/50">{subtitle}</div>
                )}
              </div>
              {renderTitleContent?.()}
            </div>
          </div>
          {isOpen ? <div className="h-[1px] w-full bg-foreground/10" /> : null}
        </div>
        <motion.div
          style={{
            height,
            opacity,
            marginTop,
          }}
          className="overflow-hidden">
          <div
            ref={contentRef}
            className={twMerge('flex w-full flex-col gap-8 px-4', className)}>
            {children}
          </div>
        </motion.div>
      </div>
    );
  },
);

StepContainer.displayName = 'StepContainer';

export default StepContainer;
