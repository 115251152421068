import {useRef, useCallback, useState, useEffect} from 'react';
import campaignCreatedSuccessImg from '@/assets/campaign-created-success-bg.png';
import interFont from '@/assets/fonts/Inter-Regular.woff2';
import {sleep} from '@/utils';

const filename = 'campaign-created.png';
const MAX_RETRIES = 30;
const RETRY_DELAY = 500;

let fontLoadingPromise: Promise<string | null> | null = null;

const preloadImage = (src: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = reject;
    img.src = src;
  });
};

const ensureFont = async () => {
  const font = await loadInterFont();
  if (!font) return null;

  // Force font to render some actual text
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return font;

  ctx.font = `20px Inter`;
  ctx.fillText('Congrats!', 0, 20); // Force render text

  return font;
};

const loadInterFont = () => {
  if (fontLoadingPromise) {
    return fontLoadingPromise;
  }

  fontLoadingPromise = fetch(interFont)
    .then(response => response.blob())
    .then(blob => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    })
    .then(base64Font => {
      const font = new FontFace('Inter', `url(${base64Font})`, {
        weight: '400',
      });

      return font.load().then(() => {
        document.fonts.add(font);
        return base64Font;
      });
    })
    .catch(error => {
      console.error('Failed to load font:', error);
      fontLoadingPromise = null;
      return null;
    });

  return fontLoadingPromise;
};

function useCanvasToShare(cb?: () => void) {
  const svgRef = useRef<SVGSVGElement>(null);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [isSvgReady, setIsSvgReady] = useState(false);
  const [base64Font, setBase64Font] = useState<string | null>(null);
  const backgroundImageRef = useRef(backgroundImage);
  const isSvgReadyRef = useRef(isSvgReady);
  const promiseRef = useRef<Promise<void> | null>(null);

  backgroundImageRef.current = backgroundImage;
  isSvgReadyRef.current = isSvgReady;

  useEffect(() => {
    // Load font immediately on mount
    loadInterFont().then(font => {
      if (font) setBase64Font(font);
    });

    // Load background image
    preloadImage(campaignCreatedSuccessImg).then(base64Image => {
      setBackgroundImage(base64Image);
      backgroundImageRef.current = base64Image;
    });
  }, []);

  // Add font injection when SVG ref is available
  useEffect(() => {
    if (svgRef.current && base64Font) {
      const defs = svgRef.current.querySelector('defs');
      if (defs) {
        const style = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'style',
        );
        style.textContent = `
          @font-face {
            font-family: 'Inter';
            src: url('${base64Font}') format('woff2');
            font-weight: 400;
            font-display: block;
          }
          text {
            font-family: 'Inter', sans-serif !important;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `;
        defs.appendChild(style);
      }
    }
  }, [svgRef.current, base64Font]);

  const waitForDependencies = useCallback(async () => {
    let retries = 0;

    while (retries < MAX_RETRIES) {
      if (
        svgRef.current &&
        backgroundImageRef.current &&
        isSvgReadyRef.current &&
        (await ensureFont()) &&
        (await document.fonts.ready)
      ) {
        // Extra wait for Safari font rendering
        await new Promise(resolve => setTimeout(resolve, 100));
        return true;
      }

      await sleep(RETRY_DELAY);
      retries++;
    }

    throw new Error(`Unable to load assets for sharing`);
  }, []);

  const waitForImageLoad = useCallback(
    (image: HTMLImageElement): Promise<void> => {
      return new Promise(resolve => {
        if (image.complete) {
          resolve();
        } else {
          image.onload = () => resolve();
        }
      });
    },
    [],
  );

  const shareImage = useCallback(async () => {
    if (promiseRef.current) {
      return promiseRef.current;
    }

    promiseRef.current = new Promise<void>((resolve, reject) => {
      waitForDependencies()
        .then(() => {
          const svgElement = svgRef.current!;

          // Force a render to canvas first to avoid blank images
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = svgElement.width.baseVal.value;
          tempCanvas.height = svgElement.height.baseVal.value;
          const tempContext = tempCanvas.getContext('2d')!;

          const tempImage = new Image();
          tempImage.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            new XMLSerializer().serializeToString(svgElement),
          )}`;

          return new Promise<void>(resolveRender => {
            tempImage.onload = () => {
              tempContext.drawImage(tempImage, 0, 0);
              resolveRender();
            };
          }).then(async () => {
            // Wait extra time for Safari
            await new Promise(resolve => setTimeout(resolve, 100));

            // Now proceed with the normal flow
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svgElement);
            return ensureFont().then(base64Font => ({svgString, base64Font}));
          });
        })
        .then(({svgString}) => {
          const svgBlob = new Blob([svgString], {
            type: 'image/svg+xml;charset=utf-8',
          });
          const url = URL.createObjectURL(svgBlob);

          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.src = url;

          return waitForImageLoad(image)
            .then(() => {
              URL.revokeObjectURL(url);
              return image;
            })
            .catch(error => {
              URL.revokeObjectURL(url);
              throw error;
            });
        })
        .then(image => {
          const svgElement = svgRef.current!;
          const canvas = document.createElement('canvas');
          canvas.width = svgElement.width.baseVal.value;
          canvas.height = svgElement.height.baseVal.value;

          const context = canvas.getContext('2d')!;

          return createImageBitmap(image).then(bitmap => {
            context.drawImage(bitmap, 0, 0);
            bitmap.close();

            return new Promise<Blob>((resolveBlob, rejectBlob) => {
              canvas.toBlob(
                resultBlob => {
                  if (resultBlob) {
                    resolveBlob(resultBlob);
                  } else {
                    rejectBlob(new Error('Failed to convert canvas to blob.'));
                  }
                },
                'image/png',
                1,
              );
            });
          });
        })
        .then(blob => {
          const file = new File([blob], filename, {type: 'image/png'});

          if (navigator.canShare && navigator.canShare({files: [file]})) {
            return navigator
              .share({
                files: [file],
                title: 'Campaign Created',
                text: 'I just created a campaign!',
              })
              .catch(() => {
                return downloadImage(blob);
              });
          } else {
            return downloadImage(blob);
          }
        })
        .then(() => {
          cb?.();
          resolve();
        })
        .catch(error => {
          console.error('Error in shareImage:', error);
          reject(error);
        })
        .finally(() => {
          promiseRef.current = null;
        });
    });

    return promiseRef.current;
  }, [waitForDependencies, waitForImageLoad, cb]);

  return {svgRef, backgroundImage, shareImage, setIsSvgReady};
}

function downloadImage(blob: Blob) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}

export default useCanvasToShare;
