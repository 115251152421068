'use client';

import {memo, SVGProps, useEffect, useRef} from 'react';
import {
  motion,
  animate,
  MotionValue,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import {twJoin} from 'tailwind-merge';

const progressToDashArray = (progress: number, radius: number) => {
  const circumference = 2 * Math.PI * radius;
  const filledLength = (circumference * progress) / 100;
  const gapLength = circumference - filledLength;

  return `${filledLength} ${gapLength}`;
};

type Props = {
  size: number;
  strokeWidth: number;
  spin?: boolean;
  fillColor?: string | string[];
  backgroundColor?: string;
  progress: number | MotionValue<number>; // Progress value between 0 and 100
} & SVGProps<SVGSVGElement>;

const FlowSpinner = memo(
  ({
    className,
    size,
    strokeWidth,
    fillColor = ['#474EFF', '#CC3FFF'],
    backgroundColor = 'rgba(255, 255, 255, 0.08)',
    spin = true,
    center,
    radius,
    progressValue,
    ...props
  }: Omit<Props, 'progress' | 'ref' | 'radius'> & {
    progressValue: MotionValue<number>;
    radius: number;
    center: number;
  }) => {
    const uniqueId = useRef(Math.random().toString(36).substring(7));
    const dasharrayValue = useTransform(progressValue, value =>
      progressToDashArray(value, radius),
    );

    return (
      <svg
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
        style={{
          width: size,
          height: size,
          ...(props.style ?? {}),
        }}>
        {Array.isArray(fillColor) ? (
          <defs>
            <radialGradient
              id={`toolLoaderGradient-${uniqueId.current}`}
              cx={size}
              cy={0}
              r={size * 1.2}
              gradientUnits="userSpaceOnUse">
              {fillColor.map((color, index) => (
                <stop
                  key={index}
                  stopColor={color}
                  offset={(index * 100) / (fillColor.length - 1)}
                />
              ))}
            </radialGradient>
          </defs>
        ) : null}
        <circle
          cx={center}
          cy={center}
          fill="none"
          r={radius}
          strokeWidth={strokeWidth}
          stroke={backgroundColor}
        />
        <motion.circle
          style={{
            strokeDasharray: dasharrayValue,
          }}
          className={twJoin(
            'origin-center',
            spin ? 'animate-[spin_1.2s_linear_infinite]' : '-rotate-90',
          )}
          cx={center}
          cy={center}
          fill="none"
          r={radius}
          strokeWidth={strokeWidth}
          stroke={
            Array.isArray(fillColor)
              ? `url(#toolLoaderGradient-${uniqueId.current})`
              : fillColor
          }
          strokeLinecap="round"
        />
      </svg>
    );
  },
);

FlowSpinner.displayName = 'FlowSpinner';

const WrappedFlowSpinner = memo(({progress, ...props}: Props) => {
  const center = props.size / 2;
  const radius = center - 2 - props.strokeWidth / 2;
  const isNumber = typeof progress === 'number';
  const initialValue = isNumber ? progress : progress.get();
  const progressValue = useMotionValue(initialValue);
  const prevProgressRef = useRef(initialValue);

  useEffect(() => {
    if (!isNumber) {
      return;
    }

    const diff = Math.abs(progress - prevProgressRef.current);

    if (diff > 1 && !progressValue.isAnimating()) {
      progressValue.set(progress);
    } else {
      animate(progressValue, progress, {
        duration: 0.3,
        ease: 'easeInOut',
      });
    }
  }, [isNumber, progress, progressValue]);

  return (
    <FlowSpinner
      {...props}
      {...{center, radius, progressValue: isNumber ? progressValue : progress}}
    />
  );
});

WrappedFlowSpinner.displayName = 'WrappedFlowSpinner';

export default WrappedFlowSpinner;
